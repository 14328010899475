import { QIT_FIREBASE_API_KEY } from 'app/constants/env';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: QIT_FIREBASE_API_KEY,
  authDomain: 'qitonline1.firebaseapp.com',
  projectId: 'qitonline1',
  storageBucket: 'qitonline1.firebasestorage.app',
  messagingSenderId: '110484428349',
  appId: '1:110484428349:web:1f46e4e2e602bceef6fc54',
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
