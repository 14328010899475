import { OrganizationPaymentType, OrganizationStatus } from 'data/enums';
import Locale from 'data/enums/Locale';
import { useAddons, useOrganisationProducts, useOrganization, useProfile } from 'data/hooks';
import { Addon, OrganisationAddon, OrganisationProduct, Organization, PriceInterval } from 'data/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PlansEnum } from '../../../subscriptions/components/card';

interface UseBillingPage {
  isPremiumPlan: boolean;
  isLoading: boolean;
  isDisabledAutomatedBilling: boolean;
  excludedInvoicing: boolean;
  organization?: Organization;
  availableAddons: Addon[];
  organisationAddons?: OrganisationAddon[];
  organisationLicence?: OrganisationProduct;
  currentInterval?: PriceInterval;
  isProductsRefetching: boolean;
  isFreeTrial: boolean;
  isCustomBilling: boolean;
}

export const useBillingPage = (disableFetchProductsAndPackages?: boolean): UseBillingPage => {
  const {
    t,
    i18n: { getFixedT, language },
  } = useTranslation();
  const nlT = getFixedT(Locale.DUTCH, null);
  const licenseTranslations = nlT('plans', { returnObjects: true });

  const { profile } = useProfile();
  const organizationId = profile?.organisation?.id || '';
  const isFreeTrial = profile.organisation?.status === OrganizationStatus.TRIAL;

  const { data: organization, isPending: isOrganizationLoading } = useOrganization(organizationId);
  const { data: addons = { products: [] }, isPending: isAddonsLoading } = useAddons(disableFetchProductsAndPackages);

  const {
    data: organisationProducts,
    isPending: isProductsLoading,
    isRefetching: isProductsRefetching,
  } = useOrganisationProducts(organizationId, disableFetchProductsAndPackages);

  const organisationAddons = useMemo(() => organisationProducts?.packages, [organisationProducts]);
  const organisationLicence = useMemo(() => {
    const translationKey = Object.entries(licenseTranslations).reduce(
      // eslint-disable-next-line
      (acc, [key, value]) => (value?.fullTitle === organisationProducts?.license?.name ? (acc = key) : acc),
      '',
    );
    return {
      description: t(`plans.${translationKey}.description`),
      name: t(`plans.${translationKey}.fullTitle`),
      price: organisationProducts?.license?.price || { id: '', amount: 0 },
      id: organisationProducts?.license?.id || '',
    };
  }, [organisationProducts, language]);

  const isCustomBilling = organization?.paymentType === OrganizationPaymentType.CUSTOM;
  const isPremiumPlan = licenseTranslations?.[PlansEnum.PREMIUM].fullTitle === organisationProducts?.license?.name;

  const availableAddons = useMemo(
    () => addons.products.filter((addon) => !organisationAddons?.some((orgAddon) => orgAddon.id === addon.id)),
    [addons, organisationAddons],
  );

  const isDisabledAutomatedBilling = organization?.paymentType === OrganizationPaymentType.CUSTOM;

  const excludedInvoicing = !organization?.paymentType;

  const currentInterval = organisationLicence.price?.interval;

  const isLoading = isOrganizationLoading || isAddonsLoading || isProductsLoading;

  return {
    isPremiumPlan,
    isLoading,
    isDisabledAutomatedBilling,
    excludedInvoicing,
    organization,
    availableAddons,
    organisationAddons,
    organisationLicence,
    currentInterval,
    isProductsRefetching,
    isFreeTrial,
    isCustomBilling,
  };
};
