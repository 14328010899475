import queryString from 'qs';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import saveBlob from 'data/utils/actions';
import { fileKeys, recordKeys } from 'data/utils/hookKeys';

import { DocumentFile, File } from 'data/types';

import { getStoredUserId } from 'data/utils/userStorage';
import { FilePurpose } from 'data/enums';
import { AxiosError } from 'axios';
import { defaultDocumentSearchParams, IRecordsDocumentsParams } from './records';
import * as api from '../actions-query';

export const useUploadFile = (params?: {
  purpose: FilePurpose;
}): UseMutationResult<File, any, { fields: { files: FormData | string | Blob } }, unknown> =>
  useMutation({
    mutationFn: ({ fields }) => {
      const { files } = fields;
      const queryParams = queryString.stringify(params);

      const userId = getStoredUserId();

      const formData = files instanceof FormData ? files : new FormData();
      if (files instanceof FormData === false) formData.append('file', files);

      return api.uploadFile(userId, formData, queryParams);
    },
  });

export const useUploadPublicFile = (): UseMutationResult<
  File,
  AxiosError,
  { formData: FormData; progressFn?: (percent: number) => void },
  unknown
> => useMutation({ mutationFn: ({ formData, progressFn }) => api.uploadFilePublic(formData, progressFn) });

export const useFile = (fileId: string) =>
  useQuery<Blob, AxiosError>({
    queryKey: fileKeys.details(fileId),
    queryFn: () => api.downloadFile(fileId),
    staleTime: Infinity,
    enabled: !!fileId,
  });

export const useDeleteFile = () =>
  useMutation<unknown, AxiosError, { fileId: string }, unknown>({ mutationFn: ({ fileId }) => api.deleteFile(fileId) });

export const useFileLink = (fileId: string) =>
  useQuery<string, AxiosError>({
    queryKey: fileKeys.link(fileId),
    queryFn: () => api.downloadFileLink(fileId),
    enabled: !!fileId,
  });

export const useDownloadFile = () =>
  useMutation<Blob, AxiosError, { fileId: string; fileName: string }, unknown>({
    mutationFn: ({ fileId }) => api.downloadFile(fileId),
    onSuccess: (blob, variables) => {
      saveBlob(blob, variables.fileName);
    },
  });

export const useOpenFile = () =>
  useMutation<Blob, AxiosError, { fileId: string }, unknown>({
    mutationFn: ({ fileId }) => api.downloadFile(fileId),
    onSuccess: (blob) => {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank', 'noopener,noreferrer');
    },
  });

export const useDownloadPublicFile = () =>
  useMutation<Blob, AxiosError, { token: string; fileName: string }, unknown>({
    mutationFn: ({ token }) => api.downloadPublicFile(token),
    onSuccess: (blob, variables) => {
      saveBlob(blob, variables.fileName);
    },
  });

export const useDownloadSessionFile = () =>
  useMutation<Blob, AxiosError, { fileId: string; fileName: string }, unknown>({
    mutationFn: ({ fileId }) => api.downloadSessionFile(fileId),
    onSuccess: (blob, variables) => {
      saveBlob(blob, variables.fileName);
    },
  });

export const useDownloadActivityFile = () =>
  useMutation<Blob, AxiosError, { fileId: string; fileName: string }, unknown>({
    mutationFn: ({ fileId }) => api.downloadActivityFile(fileId),
    onSuccess: (blob, variables) => {
      saveBlob(blob, variables.fileName);
    },
  });

export const useActivityFile = (fileId: string) =>
  useQuery<DocumentFile, AxiosError>({
    queryKey: fileKeys.activity(fileId),
    queryFn: () => api.fetchActivityFile(fileId),
  });

export const useActivityFileLink = (fileId: string) =>
  useQuery<string, AxiosError>({
    queryKey: fileKeys.activityLink(fileId),
    queryFn: () => api.fetchActivityFileLink(fileId),
    enabled: !!fileId,
  });

export const useNoteFile = (fileId: string, noteId: string) =>
  useQuery<DocumentFile, AxiosError>({
    queryKey: fileKeys.note(fileId),
    queryFn: () => api.fetchNoteFile(fileId, noteId),
    enabled: !!fileId,
  });

export const useNoteFileLink = (fileId: string) =>
  useQuery<string, AxiosError>({
    queryKey: fileKeys.noteLink(fileId),
    queryFn: () => api.fetchNoteFileLink(fileId),
    enabled: !!fileId,
  });

export const useDownloadNoteFile = () =>
  useMutation<Blob, AxiosError, { fileId: string; fileName: string }, unknown>({
    mutationFn: ({ fileId }) => api.downloadNoteFile(fileId),
    onSuccess: (blob, variables) => {
      saveBlob(blob, variables.fileName);
    },
  });

export const usePortfolioPublicFileLink = (fileId: string, resourceId: string) =>
  useQuery<string, AxiosError>({
    queryKey: fileKeys.portfolioPublicLink(fileId, resourceId),
    queryFn: () => api.fetchPortfolioPublicFileLink(fileId, resourceId),
  });

export const useQuestionnaireManual = (fileId: string) =>
  useQuery<DocumentFile, AxiosError>({
    queryKey: fileKeys.note(fileId),
    queryFn: () => api.fetchQuestionnaireManual(fileId),
    enabled: !!fileId,
  });

export const usePersonalNoteFile = (fileId: string) =>
  useQuery<DocumentFile, Error>({
    queryKey: fileKeys.note(fileId),
    queryFn: () => api.fetchPersonalNotesFile(fileId),
    enabled: !!fileId,
  });

export const useDeletePersonalNotesFile = (recordId: string, searchParams?: IRecordsDocumentsParams) => {
  const queryClient = useQueryClient();
  const userId = getStoredUserId();
  const query = queryString.stringify(searchParams || defaultDocumentSearchParams);

  return useMutation<unknown, Error, { fileId: string }, unknown>({
    mutationFn: ({ fileId }) => api.deletePersonalNotesFile(fileId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: recordKeys.documents(query, recordId, userId) });
    },
  });
};
