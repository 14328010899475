import { useEffect } from 'react';
import { userKeys, recordKeys, clientKeys } from 'data/utils/hookKeys';
import {
  QueryObserverResult,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { getStoredUserId, setStoredUserId } from 'data/utils/userStorage';
import {
  ArrayResponseType,
  BaseFetchListParams,
  BaseFetchListWithSearch,
  EditUser,
  Profile,
  User,
  UserConnection,
  UserRole,
} from 'data/types';
import { SortDirection, UserConnectionsSortNames } from 'data/enums';
import { fetchLimit, fiveMinutesInMilliseconds } from 'app/constants/app';
import { getNextPageParam } from 'data/utils/getNextPageParam';
import QueryString from 'qs';
import { AxiosError } from 'axios';
import Locale from 'data/enums/Locale';

import * as api from '../actions-query';

const profileFallback: Profile = {
  id: '',
  firstName: 'User',
  lastName: 'Name',
  email: 'email@mail.com',
  createdAt: '',
  confirmation: '',
  education: '',
  educationField: 'string',
  emergencyContactPerson: 'string',
  emergencyContactPhone: 'string',
  healthInsurance: 'string',
  isActive: false,
  maritalStatus: 'string',
  nationality: 'string',
  recordCount: 0,
  roles: [],
  unreadChatRooms: 0,
  therapists: [
    {
      id: '',
      firstName: 'User',
      lastName: 'Name',
    },
  ],
  language: Locale.ENGLISH,
};

export const useResendInvite = (): UseMutationResult<unknown, any, string, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (userId: string) => api.resendInvite(userId),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: userKeys.resend(variables) });
    },
  });
};

export const useSetProfilePicture = (
  recordId?: string,
): UseMutationResult<unknown, any, { userId: string; file: any }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ userId, file }) => {
      const previewFile = new FormData();
      previewFile.append('file', file);
      return api.setProfilePicture(userId, previewFile);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userKeys.profile() });
      queryClient.invalidateQueries({ queryKey: recordKeys.all() });
      if (recordId) {
        queryClient.invalidateQueries({ queryKey: recordKeys.detail(recordId) });
      }
    },
  });
};

export const useDeleteProfilePicture = (
  recordId: string,
): UseMutationResult<unknown, any, { userId: string }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ userId }) => api.deleteProfilePicture(userId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: recordKeys.detail(recordId) });
    },
  });
};

interface UseProfile {
  profile: Profile;
  isProfileLoading: boolean;
  isProfileFetchedAfterMount: boolean;
  refetchProfile: () => Promise<QueryObserverResult<Profile, AxiosError>>;
  isProfileRefetching: boolean;
}

export const useProfile = (token?: string): UseProfile => {
  const {
    data = profileFallback,
    isPending: isProfileLoading,
    isFetchedAfterMount: isProfileFetchedAfterMount,
    refetch: refetchProfile,
    isRefetching: isProfileRefetching,
  } = useQuery<Profile, AxiosError>({
    queryKey: userKeys.profile(),
    queryFn: () => api.getProfile(),
    refetchInterval: fiveMinutesInMilliseconds,
    enabled: !token,
    staleTime: fiveMinutesInMilliseconds,
  });

  useEffect(() => {
    if (data.id && !getStoredUserId()) {
      setStoredUserId(data.id);
    }
  }, [data]);

  return { profile: data, isProfileLoading, isProfileFetchedAfterMount, refetchProfile, isProfileRefetching };
};

export const useUsersLearnworldLink = (): UseQueryResult<string> =>
  useQuery<string, AxiosError>({ queryKey: userKeys.learnworld(), queryFn: () => api.getUsersLearnworldLink() });

export interface UserConnectionsSearchParams extends BaseFetchListWithSearch {
  sort: {
    [key in UserConnectionsSortNames]: SortDirection;
  };
}

const defaultUserConnectionsSearchParams: UserConnectionsSearchParams = {
  limit: fetchLimit,
  offset: 0,
  sort: {
    [UserConnectionsSortNames.firstName]: SortDirection.asc,
  },
};

export const useInfiniteUserConnections = (
  params = defaultUserConnectionsSearchParams,
): UseInfiniteQueryResult<ArrayResponseType<UserConnection>, AxiosError> => {
  const perPage = 30;

  const userId = getStoredUserId();
  const defaultQuery = QueryString.stringify(params);

  return useInfiniteQuery({
    queryKey: userKeys.connections(userId, defaultQuery),
    queryFn: ({ pageParam = 0 }) => {
      // we have to override offset and limit for infinite query
      const query = QueryString.stringify({ ...params, offset: pageParam, limit: perPage });

      return api.getUserConnections(userId, query);
    },
    getNextPageParam: (page, allPages) => getNextPageParam<UserConnection>(page, allPages, perPage),
    initialPageParam: 0,
  });
};

const defaultUserRolesParams: BaseFetchListParams = {
  limit: fetchLimit,
  offset: 0,
};

export const useUserRoles = (
  userId: string,
  params = defaultUserRolesParams,
): UseQueryResult<ArrayResponseType<UserRole>, AxiosError> => {
  const query = QueryString.stringify(params);

  return useQuery<ArrayResponseType<UserRole>, AxiosError>({
    queryKey: userKeys.roles(userId, query),
    queryFn: () => api.getUserRoles(userId, query),
    enabled: !!userId,
  });
};

export const useResetPasswordTokenStatus = (token: string): UseQueryResult<{ expiresAt: string }> =>
  useQuery<{ expiresAt: string }, AxiosError>({
    queryKey: userKeys.resetPasswordTokenStatus(token),
    queryFn: () => api.getResetPasswordTokenStatus(token),
  });

export const useEditUser = (): UseMutationResult<
  Partial<User>,
  AxiosError,
  { userId: string; fields: EditUser },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ fields, userId }) => api.editUser(userId, fields),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: clientKeys.all() });
    },
  });
};
