import * as React from 'react';
import { TooltipProps } from 'react-tooltip';
import { PUIColors } from '@panenco/ui';
import { OrganizationPaymentType } from 'data/enums';

type Colors = PUIColors[keyof PUIColors];

export interface LayoutContextInterface {
  isHeaderShown: boolean;
  className?: string;
  toggleIsHeaderShown: () => void;
}

export interface ViewportContextInterface {
  readonly isMobile: boolean;
  readonly isTablet: boolean;
  readonly isTabletLandscape: boolean;
  readonly isDesktop: boolean;
  readonly isDesktopLarge: boolean;
}

export interface TooltipContextInterface extends TooltipProps {
  readonly arrowColor: Colors;
}

export interface RecordManagerContextInterface {
  isOwner: boolean;
  isViewer: boolean;
  isCollaborator: boolean;
  isRecordActive: boolean;
  recordWithNoUser: boolean;
  isLegacyPendingRecord: boolean;
  isClient: boolean;
  isRecordLoading: boolean;
}

export interface FreeTrialContextInterface {
  bannerText: string | null;
  bannerButtonText: string | null;
  organisationId: string | null;
  disableButtons: boolean;
  isOrganisationAdmin: boolean;
  isOrganisationStatusActive: boolean;
  isOrganisationStatusTrial: boolean;
  isOrganisationStatusInactive: boolean;
  paymentType: OrganizationPaymentType | null;
  bannerHandleClick: (adminEmail: string) => void;
}

export interface DigitalAddonContextInterface {
  bannerText: string | null;
  bannerButtonText: string | null;
  bannerHandleClick: () => void;
  isBlockDigitalWaitingRoom: boolean;
}

export interface AffiliateContextInterface {
  isAffiliateUser: boolean;
}

const defaultFreeTrialContext: FreeTrialContextInterface = {
  bannerText: null,
  bannerButtonText: null,
  organisationId: null,
  disableButtons: false,
  paymentType: null,
  isOrganisationAdmin: false,
  isOrganisationStatusActive: false,
  isOrganisationStatusTrial: false,
  isOrganisationStatusInactive: false,
  bannerHandleClick: () => {},
};

const defaultRecordManagerContext: RecordManagerContextInterface = {
  isOwner: false,
  isViewer: false,
  isCollaborator: false,
  isRecordActive: true,
  recordWithNoUser: true,
  isLegacyPendingRecord: false,
  isClient: false,
  isRecordLoading: false,
};

const defaultViewportContext: ViewportContextInterface = {
  isMobile: false,
  isTablet: false,
  isTabletLandscape: false,
  isDesktop: false,
  isDesktopLarge: false,
};

const defaultTooltipContext: TooltipContextInterface = {
  backgroundColor: '',
  arrowColor: '',
};

const defaultLayoutContext: LayoutContextInterface = {
  isHeaderShown: true,
  toggleIsHeaderShown: () => {},
};

const defaultAffiliateContext: AffiliateContextInterface = {
  isAffiliateUser: false,
};

const defaultDigitalAddonContext: DigitalAddonContextInterface = {
  bannerText: null,
  bannerButtonText: null,
  bannerHandleClick: () => {},
  isBlockDigitalWaitingRoom: false,
};

export const RecordManagerContext = React.createContext<RecordManagerContextInterface>(defaultRecordManagerContext);
export const ViewportContext = React.createContext<ViewportContextInterface>(defaultViewportContext);
export const TooltipContext = React.createContext<TooltipContextInterface>(defaultTooltipContext);
export const LayoutContext = React.createContext<LayoutContextInterface>(defaultLayoutContext);
export const FreeTrialContext = React.createContext<FreeTrialContextInterface>(defaultFreeTrialContext);
export const AffiliateContext = React.createContext<AffiliateContextInterface>(defaultAffiliateContext);
export const DigitalAddonContext = React.createContext<DigitalAddonContextInterface>(defaultDigitalAddonContext);
