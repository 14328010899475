import { UseMutationResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { SortDirection } from 'data/enums';
import { ArrayResponseType, BaseFetchListParams, IntakeComment } from 'data/types';
import { intakesKeys } from 'data/utils/hookKeys';
import queryString from 'qs';

import * as api from '../actions-query';

export interface IntakeCommentsParams extends BaseFetchListParams {
  sort: { createdAt: SortDirection };
}

const defaultIntakeCommentsParams: IntakeCommentsParams = {
  sort: { createdAt: SortDirection.asc },
  limit: 12,
  offset: 0,
};

export const useIntakeComments = (intakeId: string, params = defaultIntakeCommentsParams) => {
  const query = queryString.stringify(params);
  return useQuery<ArrayResponseType<IntakeComment>, AxiosError>({
    queryKey: intakesKeys.comments(intakeId, query),
    queryFn: () => api.fetchIntakeComments(intakeId, query),
    enabled: !!intakeId,
  });
};

export const useCreateIntakeComment = (): UseMutationResult<
  IntakeComment,
  AxiosError,
  { intakeId: string; body: { text: string } },
  unknown
> => {
  const queryClient = useQueryClient();
  const query = queryString.stringify(defaultIntakeCommentsParams);

  return useMutation({
    mutationFn: ({ intakeId, body }) => api.createIntakeComment(intakeId, body),
    onSuccess: (_, { intakeId }) => {
      queryClient.invalidateQueries({ queryKey: intakesKeys.comments(intakeId, query) });
    },
  });
};

export const useEditIntakeComment = (): UseMutationResult<
  IntakeComment,
  AxiosError,
  { commentId: string; intakeId: string; body: { text: string } },
  unknown
> => {
  const queryClient = useQueryClient();
  const query = queryString.stringify(defaultIntakeCommentsParams);

  return useMutation({
    mutationFn: ({ commentId, body }) => api.editIntakeComment(commentId, body),
    onSuccess: (_, { intakeId }) => {
      queryClient.invalidateQueries({ queryKey: intakesKeys.comments(intakeId, query) });
    },
  });
};

export const useDeleteIntakeComment = (): UseMutationResult<
  void,
  AxiosError,
  { commentId: string; intakeId: string },
  unknown
> => {
  const queryClient = useQueryClient();
  const query = queryString.stringify(defaultIntakeCommentsParams);

  return useMutation({
    mutationFn: ({ commentId }) => api.deleteIntakeComment(commentId),
    onSuccess: (_, { intakeId }) => {
      queryClient.invalidateQueries({ queryKey: intakesKeys.comments(intakeId, query) });
    },
  });
};
