/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import {
  InteractionType,
  IntakeFormQuestionsType,
  ReferenceQuestionType,
  RecordInviteStatus,
  QuestionnaireRespondent,
} from 'data/enums';
import { FormQuestions } from 'app/components/app/therapist/intakes/pages/intake-settings-page/components/registration-form-block/components/registration-form-view/hooks/useRegistrationFormView';
import { IntakeSubmissionAnswer } from 'data/types';

const imageFileTypes = ['image/jpg', 'image/jpeg', 'image/png'];
const telephoneRegExp = /^[\+]?[(]?[0-9]{1,3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
const priceRegExp = /^\d+(\.\d{1,2})*$/;
const urlRegExp =
  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
export const emailRegExp =
  /^[a-zA-Z0-9!#$%&’*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&’*+/=?^_`{|}~-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]{2,})+$/;

const emailSchema = Yup.string().matches(emailRegExp, 'emailIsNotValid');

const emailSchemaRequired = emailSchema.required('required');

const birthdateSchema = Yup.date()
  .typeError('invalidDate')
  .max(new Date(), 'maxBirthDate')
  .min('1753-01-01T00:00:00.000Z', 'minBirthDate');

function timeIsGreater(value) {
  const { startDate, startTime } = this.parent;

  if (!value || value === '') {
    return false;
  }

  if (!startDate || !startTime) {
    return false;
  }

  const [startH, startM] = startTime.split(':');
  const [endH, endM] = value.split(':');
  return new Date(startDate).setHours(startH, startM) < new Date(startDate).setHours(endH, endM);
}
export const AddAdministratorSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  lastName: Yup.string().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  email: emailSchemaRequired,
});

Yup.addMethod(Yup.object, 'atLeastOneOf', function atLeastOneOf(list) {
  return this.test({
    name: 'atLeastOneOf',
    message: '${path} must have at least one of these keys: ${keys}',
    exclusive: true,
    params: { keys: list.join(', ') },
    test: (value) => value == null || list.some((f) => value[f] != null),
  });
});

export const TherapistSchema = Yup.object().shape({
  image: Yup.mixed().test('fileType', 'imageMustBeOfType', (value) => {
    if (!value || typeof value === 'string') {
      return true;
    }
    return imageFileTypes.includes(value[0].type);
  }),
  firstName: Yup.string().trim().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  lastName: Yup.string().trim().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  birthday: birthdateSchema.required('required'),
  email: emailSchemaRequired,
  telephone: Yup.string().matches(telephoneRegExp, 'phoneIsNotValid'),
  alternativePhone: Yup.string().matches(telephoneRegExp, 'phoneIsNotValid'),
});

export const ClientPersonalInfoSchema = Yup.object().shape({
  firstName: Yup.string().trim().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  lastName: Yup.string().trim().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  email: emailSchemaRequired,
  birthday: birthdateSchema.required('required'),
  nationality: Yup.string(),
  gender: Yup.string(),
  personalIdentificationNumber: Yup.string().required('required'),
  telephone: Yup.string().matches(telephoneRegExp, 'phoneIsNotValid'),
  activity: Yup.string(),
  education: Yup.string(),
  maritalStatus: Yup.string(),
});

export const ClientHealthInfoSchema = Yup.object().shape({
  emergencyContactPerson: Yup.string(),
  emergencyContactPhone: Yup.string().nullable().matches(telephoneRegExp, 'phoneIsNotValid'),
  generalPractitioner: Yup.string(),
  generalPractitionerPhone: Yup.string().matches(telephoneRegExp, 'phoneIsNotValid'),
  insuranceCompany: Yup.string(),
  insuranceId: Yup.string(),
  medication: Yup.string(),
});

export const ClientAddressInfoSchema = Yup.object().shape({
  address: Yup.string(),
  country: Yup.string(),
  townShip: Yup.string(),
  postalCode: Yup.string(),
});

export const ClientSchema = Yup.object().shape({
  firstName: Yup.string().trim().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  lastName: Yup.string().trim().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  email: emailSchemaRequired,
  image: Yup.mixed().test('fileType', 'imageMustBeOfType', (value) => {
    if (!value || typeof value === 'string') {
      return true;
    }
    return imageFileTypes.includes(value[0].type);
  }),
});

export const EditClientSchema = (email?: string, inviteStatus?: boolean | RecordInviteStatus) =>
  ClientSchema.shape({
    email: email && inviteStatus === RecordInviteStatus.ACCEPTED ? emailSchemaRequired : emailSchema.nullable(),
    personalIdentificationNumber: Yup.string().nullable(),
  });

export const EditUserSchema = Yup.object().shape({
  email: emailSchemaRequired,
});

export const InviteClientSchema = Yup.object().shape({
  email: emailSchema,
});

export const MemberSchema = Yup.object().shape({
  firstName: Yup.string().trim().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  lastName: Yup.string().trim().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  email: emailSchema,
  status: Yup.string().required('required'),
  phone: Yup.string().matches(telephoneRegExp, 'phoneIsNotValid'),
});
export const ClientTreatmentSchema = Yup.object().shape({
  startDate: Yup.date(),
  endDate: Yup.date(),
  natureTreatment: Yup.string(),
  typeOfTrajectory: Yup.object(),
  treatmentSpecification: Yup.string(),
  previousHealthcare: Yup.string(),
  referrer: Yup.string(),
  otherHealthcareProviders: Yup.string(),
  registrationComplaints: Yup.string(),
  helpRequest: Yup.string(),
  clinicalDisorders: Yup.string(),
  personalityDisorders: Yup.string(),
  somaticDisorders: Yup.string(),
  psychosocialFactors: Yup.string(),
  gafScore: Yup.string(),
  medication: Yup.string(),
  patientDescription: Yup.string(),
  dbcNumber: Yup.string(),
  generalPractitioner: Yup.string(),
  generalPractitionerPhone: Yup.string().matches(telephoneRegExp, 'phoneIsNotValid'),

  currentFunctioning: Yup.string(),
  history: Yup.string(),
  context: Yup.string(),
  resilience: Yup.string(),
});

export const AffiliateSchema = Yup.object().shape({
  email: emailSchemaRequired,
});

export const TemplateSchema = Yup.object().shape({
  title: Yup.string().min(2, 'tooShort').max(255, 'tooLong').required('required'),
  content: Yup.string(),
});

export const TrajectoryAddSchema = Yup.object().shape({
  protocol: Yup.string().required('required'),
});

export const OrganizationAddSchema = Yup.object().shape({
  name: Yup.string().trim().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  manager: Yup.string().email('invalidEmail').required('required'),
});

export const OrganizationSchema = Yup.object().shape({
  name: Yup.string().trim().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  website: Yup.string().matches(urlRegExp, 'invalidURL'),
});

export const OrganizationUpdateSchema = Yup.object().shape({
  name: Yup.string().trim().min(2, 'tooShort').max(50, 'tooLong').required('required'),
});

export const OrganizationSignUpSchema = Yup.object().shape({
  firstName: Yup.string().trim().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  lastName: Yup.string().trim().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  manager: emailSchemaRequired,
  name: Yup.string().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  infoProcessingOrganisationConsent: Yup.bool().oneOf([true], 'fieldMustBeChecked').required('fieldMustBeChecked'),
  privacyPolicy: Yup.bool().oneOf([true], 'fieldMustBeChecked').required('fieldMustBeChecked'),
});

export const ManagerSchema = Yup.object().shape({
  manager: Yup.string().email('invalidEmail').required('required'),
});

export const InviteTherapistSchema = Yup.object().shape({
  email: emailSchemaRequired,
  type: Yup.string().required(),
});

export const CreateRecordSchema = (isInviting) =>
  Yup.object().shape({
    email: isInviting ? emailSchemaRequired : emailSchema,
    firstName: Yup.string().min(2, 'tooShort').max(50, 'tooLong').required('required'),
    lastName: Yup.string().min(2, 'tooShort').max(50, 'tooLong').required('required'),
    inviteClient: Yup.bool().oneOf([false, true]),
  });

export const AddRecordRoleSchema = Yup.object().shape({
  email: emailSchema.when('sendInvite', { is: true, then: (schema) => schema.required('required') }),
  firstName: Yup.string().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  lastName: Yup.string().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  type: Yup.string().min(2, 'tooShort').max(50, 'tooLong'),
  phone: Yup.string().matches(telephoneRegExp, 'phoneIsNotValid'),
  address: Yup.string().min(2, 'tooShort').max(255, 'tooLong'),
  additionalInfo: Yup.string().min(2, 'tooShort').max(255, 'tooLong'),
  sendInvite: Yup.boolean(),
  permissionType: Yup.string().nullable(),
});

export const EditRecordRoleSchema = Yup.object().shape({
  email: emailSchema,
  firstName: Yup.string().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  lastName: Yup.string().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  type: Yup.string().min(2, 'tooShort').max(50, 'tooLong'),
  phone: Yup.string().matches(telephoneRegExp, 'phoneIsNotValid'),
  address: Yup.string().min(2, 'tooShort').max(255, 'tooLong'),
  additionalInfo: Yup.string().min(2, 'tooShort').max(255, 'tooLong'),
});

export const AddMemberSchema = Yup.object().shape({
  email: emailSchemaRequired,
  firstName: Yup.string().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  lastName: Yup.string().min(2, 'tooShort').max(50, 'tooLong').required('required'),
});

export const QuestionnaireBuilderFirst = Yup.object().shape({
  name: Yup.string().trim().min(2, 'tooShort').required('required'),
  respondent: Yup.array().min(1, 'required'),
  abbreviation: Yup.string().required('required'),
  price: Yup.string().matches(priceRegExp, 'invalidPrice').required('required'),
  authors: Yup.string().required('required'),
  targetAudience: Yup.string().required('required'),
  goals: Yup.string().required('required'),
  language: Yup.string().required('required'),
  scales: Yup.string().required('required'),
  scoring: Yup.string().required('required'),
  norms: Yup.string().required('required'),
  introduction: Yup.string().required('required'),
  thankYouText: Yup.string().required('required'),
});

const typesArray = ['radio', 'checkbox', 'dropdown'];

export const QuestionnaireBuilderSecond = Yup.object().shape({
  questions: Yup.array()
    .min(1, 'you should have minimum 1 question')
    .of(
      Yup.object().shape({
        text: Yup.string().required('required'),
        type: Yup.string().required('required'),
        // if type of question === radio/checkbox/dropdown we must have at least 1 answer option
        answerOptions: Yup.array().when('type', {
          is: (type) => typesArray.includes(type),
          then: (schema) =>
            schema.min(1, 'minimumOneAnswer').of(
              Yup.object().shape({
                text: Yup.string().required('required'),
                value: Yup.number().required('required'),
                // validating that skip to is actually skipping 2 questions or more
                skipTo: Yup.number()
                  .nullable()
                  .test(
                    'minimum of skip to = 2',
                    'skipTwoQuestions',
                    (value, context) =>
                      value > Number(context.path.charAt(context.path.search(/[0-9]/))) + 2 ||
                      value === undefined ||
                      value === null,
                  )
                  // validating that skip to value is actually in array
                  .test(
                    'skip to value must exist',
                    'skipToDontExist',
                    (value, context) =>
                      value <= context.from[2].value.questions.length || value === undefined || value === null,
                  ),
              }),
            ),
        }),
        labelMin: Yup.string()
          .when('type', {
            is: 'slider',
            then: (schema) => schema.required('required'),
          })
          .nullable(),
        labelMax: Yup.string()
          .when('type', {
            is: 'slider',
            then: (schema) => schema.required('required'),
          })
          .nullable(),
        min: Yup.number()
          .when('type', {
            is: 'slider',
            then: (schema) => schema.integer().lessThan(Yup.ref('max'), 'minLessThanMax').required('required'),
          })
          .nullable(),
        max: Yup.number()
          .when('type', {
            is: 'slider',
            then: (schema) => schema.integer().positive().required('required'),
          })
          .nullable(),
        step: Yup.number()
          .when('type', {
            is: 'slider',
            then: (schema) =>
              schema
                .integer('onlyInteger')
                .positive('onlyPositiveStep')
                .max(Yup.ref('max'), 'stepLessThanMax')
                // custom function to check if we can reach the maximum value with step value (slider)
                .test(
                  'stepValidation',
                  'cantReachMax',
                  (value, context) => (context.parent.max - context.parent.min) % value === 0,
                )
                .required('required'),
          })
          .nullable(),
      }),
    )
    .required('required'),
});

export const ReportSchema = Yup.object().shape({
  name: Yup.string().required('required'),
  completedBy: Yup.string().required('required'),
  questionnaire: Yup.string().required('required'),
});

export const ResetPasswordSchema = Yup.object().shape({
  email: emailSchemaRequired,
});

export const ConfirmPasswordSchema = Yup.object().shape({
  password: Yup.string().trim().min(10, 'passwordMinLength').required('passwordIsRequired'),
  repeat: Yup.string()
    .oneOf([Yup.ref('password'), null], 'passwordsMustMatch')
    .required('passwordIsRequired'),
});

export const SignIn = Yup.object().shape({
  email: emailSchema,
  password: Yup.string().required('required'),
});

export const SignUpPageShared = Yup.object().shape({
  firstName: Yup.string().trim().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  lastName: Yup.string().trim().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  email: emailSchemaRequired,
  telephone: Yup.string().matches(telephoneRegExp, 'phoneIsNotValid'),
  birthday: birthdateSchema.required('required').nullable().default(undefined),
  gender: Yup.string(),
  nationality: Yup.string(),
  education: Yup.string(),
  password: Yup.string().trim().min(10, 'passwordMinLength').required('passwordIsRequired'),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref('password'), null], 'passwordsMustMatch')
    .required('passwordIsRequired'),
  infoProcessingOrganisationConsent: Yup.bool().oneOf([true], 'fieldMustBeChecked').required('fieldMustBeChecked'),
  privacyPolicy: Yup.bool().oneOf([true], 'fieldMustBeChecked').required('fieldMustBeChecked'),
});

export const SignUpPageFirstClient = Yup.object().shape({
  firstName: Yup.string().trim().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  lastName: Yup.string().trim().min(2, 'tooShort').max(50, 'tooLong').required('required'),
  password: Yup.string().trim().min(10, 'passwordMinLength').required('passwordIsRequired'),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref('password'), null], 'passwordsMustMatch')
    .required('passwordIsRequired'),
  infoProcessingOrganisationConsent: Yup.bool().oneOf([true], 'fieldMustBeChecked').required('fieldMustBeChecked'),
});

export const SignUpPageSecond = Yup.object().shape({
  education: Yup.string(),
  experience: Yup.number(),
  function: Yup.string(),
  organization: Yup.string(),
  workAddress: Yup.string(),
  framework: Yup.string(),
});

export const SignUpPageThird = Yup.object().shape({
  country: Yup.string(),
  town: Yup.string(),
  address: Yup.string(),
  code: Yup.string(),
});

export const SignUpPageFifth = Yup.object().shape({
  generalPractitioner: Yup.string().trim().min(2, 'tooShort'),
  generalPractitionerPhone: Yup.string().matches(telephoneRegExp, 'phoneIsNotValid'),
  insuranceCompany: Yup.string().trim().min(2, 'tooShort'),
  referrer: Yup.string().trim().min(2, 'tooShort'),
  previousHealthcare: Yup.string().trim().min(2, 'tooShort'),
  otherHealthcareProviders: Yup.string().trim().min(2, 'tooShort'),
  medication: Yup.string().trim().min(2, 'tooShort'),
});

export const ReportPageFirst = Yup.object().shape({
  name: Yup.string().trim().min(2, 'tooShort').required('required'),
  questionnaire: Yup.object().required('required'),
});

const testCalculationAtLeastOneValue = (_, context) =>
  context.parent.questions?.length || context.parent.childCalculations?.length || context.parent.constant;
const testCalculationAtLeastOneValueKey = 'atLeastOneValueForCalculation';
export const ReportPageSecond = Yup.object().shape({
  calculations: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('required'),
        type: Yup.string().required('required'),
        maxResult: Yup.number().optional().nullable().min(-1000).max(1000),
        questions: Yup.string()
          .optional()
          .test(testCalculationAtLeastOneValueKey, testCalculationAtLeastOneValueKey, testCalculationAtLeastOneValue),
        childCalculations: Yup.array()
          .optional()
          .test(testCalculationAtLeastOneValueKey, testCalculationAtLeastOneValueKey, testCalculationAtLeastOneValue),
        constant: Yup.number()
          .min(-1000)
          .max(1000)
          .nullable()
          .optional()
          .test(testCalculationAtLeastOneValueKey, testCalculationAtLeastOneValueKey, testCalculationAtLeastOneValue),
      }),
    )
    .required('required'),
});

export const ReportPageThird = Yup.object().shape({
  norms: Yup.array()
    .of(
      Yup.object().shape(
        {
          name: Yup.string().required('required'),
          type: Yup.string().required('required'),
          basisValue: Yup.object().required('required'),
          timespan: Yup.string().required('required'),
          definitions: Yup.array()
            .of(
              Yup.object().shape({
                backgroundColor: Yup.string(),
                graphBackgroundColor: Yup.string(),
                text: Yup.string().required('required'),
                textColor: Yup.string(),
                upperLimit: Yup.string().required('required'),
              }),
            )
            .required('required'),
        },
        ['calculation', 'question'],
      ),
    )
    .required('required'),
});

const graphTypes = ['scores_graph', 'evolution_graph'];

export const ReportPageFourth = Yup.object().shape({
  graphs: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().trim().min(2, 'tooShort').required('required'),
        type: Yup.string().required('required'),
        minScale: Yup.number().required('required'),
        maxScale: Yup.number().required('required'),
        intervalScale: Yup.number()
          .positive('onlyPositiveScale')
          .test(
            'scaleInterval',
            'scaleInterval',
            (value, context) => context.parent.maxScale - context.parent.minScale > value,
          )
          .required('required'),
        // scoreColor: Yup.string().required('required'),
        intervals: Yup.string().when('type', {
          is: 'evolution_graph',
          then: (schema) => schema.required('required'),
        }),
        orientation: Yup.string().when('type', {
          is: 'scores_graph',
          then: (schema) => schema.required('required'),
        }),
        questions: Yup.array().when('type', {
          is: (type) => graphTypes.includes(type),
          then: (schema) =>
            schema.test(
              'minimum one of questions, norms, calculations',
              '',
              (value, context) =>
                context.parent.calculations?.length > 0 || value?.length > 0 || context.parent.norms?.length > 0,
            ),
        }),
        norms: Yup.array().when('type', {
          is: (type) => graphTypes.includes(type),
          then: (schema) =>
            schema.test(
              'minimum one of questions, norms, calculations',
              '',
              (value, context) =>
                context.parent.calculations?.length > 0 || context.parent.questions?.length > 0 || value?.length > 0,
            ),
        }),
        calculations: Yup.array().when('type', {
          is: (type) => graphTypes.includes(type),
          then: (schema) =>
            schema.test(
              'minimum one of questions, norms, calculations',
              '',
              (value, context) =>
                value?.length > 0 || context.parent.questions?.length > 0 || context.parent.norms?.length > 0,
            ),
        }),
      }),
    )
    .required('required'),
});

const valueTypes = ['patient', 'session'];

export const ReportPageFifth = Yup.object().shape({
  reportStructure: Yup.object().shape({
    title: Yup.string().trim().min(2, 'tooShort').required('required'),
    subtitle: Yup.string().required('required'),
    reportBlocks: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string().required('required'),
          content: Yup.array().of(
            Yup.object().shape({
              valueType: Yup.string(),
              norm: Yup.object().when('valueType', {
                is: (val) => val === 'factsEnumeration',
                then: (schema) =>
                  schema.when('type', {
                    is: (val) => val === 'norm',
                    then: (schema) => schema.required('required'),
                  }),
              }),
              question: Yup.number()
                .nullable()
                .when('valueType', {
                  is: (val) => val === 'factsEnumeration',
                  then: (schema) =>
                    schema.nullable().when('type', {
                      is: (val) => val === 'question',
                      then: (schema) => schema.nullable().required('required'),
                    }),
                }),
              propertyName: Yup.string().when('valueType', {
                is: (val) => val === 'factsEnumeration',
                then: (schema) =>
                  schema.when('type', {
                    is: (val) => valueTypes.includes(val),
                    then: (schema) => schema.required('required'),
                  }),
              }),
              calculation: Yup.mixed().when('valueType', {
                is: (val) => val === 'factsEnumeration',
                then: (schema) =>
                  schema.when('type', {
                    is: (val) => val === 'calculation',
                    then: (schema) => schema.required('required'),
                  }),
              }),
              timespan: Yup.string().when('valueType', {
                is: (val) => val === 'factsEnumeration',
                then: (schema) =>
                  schema.when('type', {
                    is: (val) => val === 'calculation',
                    then: (schema) => schema.required('required'),
                  }),
              }),
              label: Yup.string().when('valueType', {
                is: (val) => val === 'factsEnumeration',
                then: (schema) => schema.required('required'),
              }),
              graph: Yup.mixed().when('valueType', {
                is: (val) => val === 'graphContent',
                then: (schema) => schema.required('required'),
              }),
              title: Yup.string().when('valueType', {
                is: (val) => val === 'text',
                then: (schema) => schema.required('required'),
              }),
              textContentItem: Yup.object().when('valueType', {
                is: (val) => val === 'factsEnumeration',
                then: (schema) =>
                  schema.when('type', {
                    is: (val) => val === 'text',
                    then: (schema) =>
                      schema.shape({
                        title: Yup.string().required('required'),
                        value: Yup.string().required('required'),
                      }),
                  }),
              }),

              value: Yup.string().when('valueType', {
                is: (val) => val === 'text',
                then: (schema) => schema.required('required'),
              }),
              tableName: Yup.string().when('valueType', {
                is: (val) => val === 'tableContent',
                then: (schema) => schema,
              }),
              tableCols: Yup.string().when('valueType', {
                is: (val) => val === 'tableContent',
                then: (schema) => schema.required('required'),
              }),
              tableRows: Yup.string().when('valueType', {
                is: (val) => val === 'tableContent',
                then: (schema) => schema.required('required'),
              }),
              type: Yup.string().when('valueType', {
                is: (val) => val === 'factsEnumeration',
                then: (schema) => schema.required('required'),
              }),
              rows: Yup.array().when('valueType', {
                is: (val) => val === 'tableContent',
                then: (schema) =>
                  schema
                    .of(
                      Yup.object().shape({
                        columns: Yup.array()
                          .min(1, 'minimum 1 column')
                          .of(
                            Yup.object().shape({
                              type: Yup.string(),
                              timespan: Yup.string().when('type', {
                                is: 'calculation',
                                then: (schema) => schema.required('required'),
                              }),
                              propertyName: Yup.string().when('type', {
                                is: (v) => valueTypes.includes(v),
                                then: (schema) => schema.required('required'),
                              }),
                              question: Yup.number()
                                .nullable()
                                .when('type', {
                                  is: 'question',
                                  then: (schema) => schema.nullable().required('required'),
                                }),
                              textContentItem: Yup.object().when('type', {
                                is: 'text',
                                then: (schema) =>
                                  schema.shape({
                                    value: Yup.string(),
                                  }),
                              }),
                              calculation: Yup.object().when('type', {
                                is: 'calculation',
                                then: (schema) => schema.required('required'),
                              }),
                              norm: Yup.object().when('type', {
                                is: 'norm',
                                then: (schema) => schema.required('required'),
                              }),
                            }),
                          ),
                      }),
                    )
                    .min(1, 'minimum 1 row')
                    .required('required'),
              }),
            }),
          ),
        }),
      )
      .required('required'),
  }),
});
export const ProtocolSchema = Yup.object().shape({
  name: Yup.string().trim().min(2, 'tooShort').required('required'),
  type: Yup.string().required('required'),
  active: Yup.bool(),
  sessions: Yup.array()
    .of(
      Yup.object().shape({
        active: Yup.bool(),
        // questionnaires: Yup.array().min(1),
      }),
    )
    .min(1),
});

export const ImportCalendarSchema = Yup.object().shape({
  name: Yup.string().required('required'),
  colour: Yup.string(),
});

export const AddCalendarInteractionSchema = Yup.object().shape({
  name: Yup.string().trim().min(2, 'tooShort').required('required'),
  type: Yup.string().oneOf([InteractionType.PHYSICAL_INTERACTION, InteractionType.VIDEO_INTERACTION]),
  client: Yup.object().required('required'),
  startDate: Yup.date().required('required'),
  startTime: Yup.string().required('required'),
  endTime: Yup.mixed().test('isGreater', 'endTimeGreater', timeIsGreater),
  address: Yup.string().when('type', {
    is: (val) => val === InteractionType.PHYSICAL_INTERACTION,
    then: (schema) => schema.min(2, 'tooShort').max(75, 'tooLong').required('required'),
  }),
});

export const AddCustomEventSchema = Yup.object().shape({
  title: Yup.string().min(2, 'tooShort').max(255, 'tooLong').required('required'),
  startDate: Yup.date().required('required'),
  startTime: Yup.string().required('required'),
  endTime: Yup.mixed().test('isGreater', 'endTimeGreater', timeIsGreater),
  description: Yup.string(),
});

export const AddInteractionSchema = Yup.object().shape({
  name: Yup.string().trim().min(2, 'tooShort').required('required'),
  type: Yup.string().oneOf([InteractionType.PHYSICAL_INTERACTION, InteractionType.VIDEO_INTERACTION]),
  startDate: Yup.date().required('required'),
  startTime: Yup.string().required('required'),
  endTime: Yup.mixed()
    // eslint-disable-next-line func-names
    .test('isGreater', 'endTimeGreater', timeIsGreater),
  address: Yup.string().when('type', {
    is: (val) => val === InteractionType.PHYSICAL_INTERACTION,
    then: (schema) => schema.min(2, 'tooShort').max(75, 'tooLong').required('required'),
    otherwise: (schema) =>
      schema.when('type', {
        is: (val) => val === InteractionType.VIDEO_INTERACTION,
        then: (sch) => sch.notRequired(),
      }),
  }),
});

export const AddTaskSchema = (isSequenceFlow = false) =>
  Yup.object().shape({
    title: Yup.string().trim().min(2, 'tooShort').required('required'),
    description: Yup.string(),
    url: Yup.string().matches(urlRegExp, 'invalidURL'),
    startDate: isSequenceFlow ? Yup.date().nullable() : Yup.date().required('required'),
  });

export const AddSyncSchema = Yup.object().shape({
  name: Yup.string().trim().min(2, 'tooShort').required('required'),
  startDate: Yup.date().required('required'),
  participant: Yup.string().min(2, 'tooShort').max(75, 'tooLong').required('required'),
});

export const EndTrajSchema = Yup.object().shape({
  active: Yup.string().required('required'),
});

export const CreateModule = Yup.object().shape({
  title: Yup.string().trim().min(2, 'tooShort').required('required'),
  url: Yup.string().trim().required('required').matches(urlRegExp, 'invalidURL'),
  videoUrl: Yup.string().trim().required('required').matches(urlRegExp, 'invalidURL'),
  goal: Yup.string().trim().min(2, 'tooShort').required('required'),
  price: Yup.number().min(0, 'invalidPrice').required('required'),
  authors: Yup.string().trim().min(2, 'tooShort').required('required'),
  focus: Yup.array().min(1, 'required').required('required'),
  respondents: Yup.array().min(1, 'required').required('required'),
  description: Yup.string().required('required'),
});

export const CreateExercise = Yup.object().shape({
  title: Yup.string().trim().min(2, 'tooShort').required('required'),
  goal: Yup.string().trim().min(2, 'tooShort').max(255, 'tooLong').required('required'),
  authors: Yup.string().trim().min(2, 'tooShort'),
  focus: Yup.array(),
  respondents: Yup.array(),
  description: Yup.string().required('required'),
  language: Yup.string().required('required'),
  visibility: Yup.string().required('required'),
});

export const AddModule = (isSequenceFlow = false) =>
  Yup.object().shape({
    startDate: isSequenceFlow ? Yup.date().nullable() : Yup.date().required('required'),
    name: Yup.string().trim().min(2, 'tooShort').required('required'),
    task: Yup.string().nullable().required(''),
  });

const respondentSchema = (isVersionRequired: boolean, isLanguageRequired: boolean) =>
  Yup.object().shape({
    checked: Yup.boolean(),
    questionnaireVersion: Yup.string().when('checked', (checked, schema) =>
      // checked have type boolean[] that why we check first element
      checked[0] && isVersionRequired ? schema.required('selectAVersion') : schema.nullable(),
    ),
    questionnaireLanguage: Yup.string().when('checked', (checked, schema) =>
      // checked have type boolean[] that why we check first element
      checked[0] && isLanguageRequired ? schema.required('selectALanguage') : schema.nullable(),
    ),
  });

export const AddQuestionnaire = Yup.object().shape({
  startDate: Yup.date().required('required'),
  name: Yup.string().trim().min(2, 'tooShort').required('required'),
  questionnaireGroups: Yup.lazy((groups) =>
    Yup.object(
      Object.keys(groups || {}).reduce((acc, groupId) => {
        const isVersionRequired =
          // version is required if we have more than 1 questionnaire in group
          // and all of them have version property
          groups[groupId]?.questionnaires?.length > 1 &&
          !groups[groupId]?.questionnaires?.find((questionnaire) => !questionnaire.version);
        // language is required if we have more than 1 questionnaire in group
        // and all of them have language property
        const isLanguageRequired =
          groups[groupId]?.questionnaires?.length > 1 &&
          !groups[groupId]?.questionnaires.find((questionnaire) => !questionnaire.language);
        acc[groupId] = Yup.object().shape({
          respondents: Yup.lazy((respondents) =>
            Yup.object(
              Object.keys(respondents || {}).reduce((acc, respondentId) => {
                acc[respondentId] = respondentSchema(isVersionRequired, isLanguageRequired);
                return acc;
              }, {}),
            ),
          ),
        });
        return acc;
      }, {}),
    ),
  ),
});

const respondentFromDetailsPageSchema = (isVersionRequired: boolean, isLanguageRequired: boolean) =>
  Yup.object().shape({
    questionnaireVersion: isVersionRequired ? Yup.string().required('selectAVersion') : Yup.string(),
    questionnaireLanguage: isLanguageRequired ? Yup.string().required('selectALanguage') : Yup.string(),
  });

export const AddQuestionnaireFromDetailsPageSchema = (isVersionRequired: boolean, isLanguageRequired: boolean) =>
  Yup.object().shape({
    startDate: Yup.date().required('required'),
    name: Yup.string().trim().min(2, 'tooShort').required('required'),
    respondents: Yup.lazy((respondents) =>
      Yup.object(
        Object.keys(respondents || {}).reduce((acc, respondentId) => {
          acc[respondentId] = respondentFromDetailsPageSchema(isVersionRequired, isLanguageRequired);
          return acc;
        }, {}),
      ).test(
        'has-at-least-one-item',
        'respondentsMustHaveAtLeastOneItem',
        (value) => value && Object.keys(value).length > 0,
      ),
    ),
  });

export const NoteBody = Yup.string().max(45000, 'noteMaxCharsNumber');

export const TreatmentInfoSchema = Yup.object().shape({
  conventionType: Yup.boolean().nullable(),
  conventionNetwork: Yup.string()
    .nullable()
    .when('conventionType', {
      is: true,
      then: (schema) => schema.min(1, 'required').required('required'),
    }),
});

export const PortfolioContactSectionSchema = Yup.object().shape({
  firstName: Yup.string().trim().min(2, 'tooShort').max(255, 'tooLong').required('required'),
  lastName: Yup.string().trim().min(2, 'tooShort').max(255, 'tooLong').required('required'),
  email: emailSchema,
  phone: Yup.string().matches(telephoneRegExp, 'phoneIsNotValid'),
  organisation: Yup.string().trim().min(2, 'tooShort').max(255, 'tooLong'),
  website: Yup.string().matches(urlRegExp, 'invalidURL'),
  jobTitle: Yup.string().trim().min(2, 'tooShort').max(255, 'tooLong'),
});

export const PortfolioSectionSchema = Yup.object().shape({
  title: Yup.string().trim().min(2, 'tooShort').max(40, 'tooLong').required('required'),
  textField: Yup.string().required('required'),
});

const numberStringRegex = /^(-?\d+(\.\d+)?(\s+|$))*$/;
const decimalNumberRegex = /^-?\d+(\.\d+)?$/;

export const EditDefinitionsSchema = Yup.object().shape({
  startFrom: Yup.string().matches(decimalNumberRegex, 'invalidNumber'),
  limits: Yup.string().matches(numberStringRegex, 'invalidNumbersSpaceSeparated'),
  scores: Yup.string().matches(numberStringRegex, 'invalidNumbersSpaceSeparated').required('required'),
  textNorm: Yup.object().nullable().required('required'),
});

export const AddTagSchema = Yup.object().shape({
  name: Yup.string().trim().min(2, 'tooShort').max(40, 'tooLong').required('required'),
  type: Yup.string().trim().min(2, 'tooShort').max(40, 'tooLong').required('required'),
});

export const AddPublisherSchema = Yup.object().shape({
  name: Yup.string().trim().min(2, 'tooShort').max(40, 'tooLong').required('required'),
  requiresSignature: Yup.bool().oneOf([false, true]),
});

export const AddQuestionnaireGroupSchema = Yup.object().shape({
  name: Yup.string().trim().min(2, 'tooShort').required('required'),
});

export const createGroupSchema = Yup.object().shape({
  name: Yup.string().trim().min(2, 'tooShort').max(40, 'tooLong').required('required'),
});

const intakeQuestionsWithOptions = [IntakeFormQuestionsType.SINGLE_SELECT, IntakeFormQuestionsType.MULTI_SELECT];

export const createIntakeFormValidationSchema = (formQuestions: FormQuestions[]) =>
  Yup.object().shape(
    formQuestions.reduce((acc, { name, type, isRequired, reference }) => {
      const fieldWithType = intakeQuestionsWithOptions.includes(type) ? Yup.array() : Yup.string();
      const birthdayField = reference === ReferenceQuestionType.BIRTHDAY ? birthdateSchema : fieldWithType;
      const fieldWithIsRequired = isRequired ? fieldWithType.required('required') : birthdayField;
      return { ...acc, [name]: fieldWithIsRequired };
    }, {}),
  );

export const addIntakeManuallySchema = ({
  emailQuestionId,
  firstNameQuestionId,
  lastNameQuestionId,
}: {
  emailQuestionId: string;
  firstNameQuestionId: string;
  lastNameQuestionId: string;
}) =>
  Yup.object().shape({
    [emailQuestionId]: emailSchema.when('notifyClient', {
      is: true,
      then: () => emailSchemaRequired,
    }),
    [firstNameQuestionId]: Yup.string().required('required'),
    [lastNameQuestionId]: Yup.string().required('required'),
  });

export const editIntakeSchema = ({
  intakeAnsweredQuestions,
  emailQuestionId,
}: {
  intakeAnsweredQuestions: IntakeSubmissionAnswer[];
  emailQuestionId: string;
}) => {
  const isEmailAnswered = !!intakeAnsweredQuestions?.find((question) => question.question.id === emailQuestionId);
  const dynamicSchema = intakeAnsweredQuestions?.reduce((acc, answer) => {
    // answered questions cannot be empty after edit
    acc[answer.question.id] = answer?.value ? Yup.string().required('required') : Yup.array().min(1, 'required');
    return acc;
  }, {});

  return Yup.object().shape({
    questions: Yup.array().of(
      Yup.object().shape({ answer: Yup.string().required('required'), text: Yup.string().required('required') }),
    ),
    ...dynamicSchema,
    [emailQuestionId]: isEmailAnswered ? emailSchemaRequired : emailSchema,
  });
};

export const IntakeBuilderSchema = Yup.object().shape({
  messageIntro: Yup.string().required('required'),
  questions: Yup.array()
    .min(1, 'you should have minimum 1 question')
    .of(
      Yup.object().shape({
        text: Yup.string().required('required'),
        type: Yup.string().required('required'),
        reference: Yup.string()
          .nullable()
          .when('type', {
            is: (type: IntakeFormQuestionsType) => type === IntakeFormQuestionsType.FIELD_REFERENCE,
            then: (schema) => schema.required('required'),
          }),
        options: Yup.array().when('type', {
          is: (type: IntakeFormQuestionsType) => intakeQuestionsWithOptions.includes(type),
          then: (schema) =>
            schema.min(1, 'minimumOneAnswer').of(
              Yup.object().shape({
                text: Yup.string().required('required'),
              }),
            ),
        }),
      }),
    )
    .required('required'),
});
export const getCreateTableSchema = (maxCoulnsCount: number, maxRowsCount: number) =>
  Yup.object().shape({
    columns: Yup.number().min(1, 'columnsCountTooSmall').max(maxCoulnsCount, 'columnsCountTooBig').required('required'),
    rows: Yup.number().min(1, 'rowsCountTooSmall').max(maxRowsCount, 'rowsCountTooBig').required('required'),
  });

export const getAddMaxNumberOfOrganisationUsers = (amountOfUsers: number) =>
  Yup.object().shape({
    maxMembers: Yup.number().min(amountOfUsers, 'maxMembersTooSmall').required('required'),
  });

export const CreateQuestionnaireGroupSchema = Yup.object().shape({
  name: Yup.string().required('required'),
  abbreviation: Yup.string().required('required'),
  goals: Yup.string().required('required'),
  questionnaires: Yup.array().min(1, 'minimumOneQuestionnaire').of(Yup.string()).required('required'),
});

export const ImportIntakesFormSchema = Yup.object().shape({
  data: Yup.array()
    .min(1, 'minimumOneIntake')
    .of(
      Yup.object().shape({
        firstName: Yup.string().required('required'),
        lastName: Yup.string().required('required'),
        email: emailSchemaRequired,
      }),
    )
    .required('required'),
});

export const CreateTodoSchema = Yup.object().shape({
  body: Yup.string().trim().min(2, 'tooShort').max(255, 'tooLong').required('required'),
  dueDate: Yup.date().required('required'),
  dueTime: Yup.string().required('required'),
  complete: Yup.boolean(),
  record: Yup.object().nullable(),
});

export const EditTodoSchema = Yup.object().shape({
  body: Yup.string().trim().min(2, 'tooShort').max(255, 'tooLong').required('required'),
  dueDate: Yup.date().required('required'),
  dueTime: Yup.string().required('required'),
  complete: Yup.boolean(),
  record: Yup.object().nullable(),
});

export const sequenceBuilderValidationSchema = Yup.object().shape({
  name: Yup.string().trim().min(2, 'tooShort').required('required'),
  description: Yup.string().trim().min(2, 'tooShort').required('required'),
  active: Yup.boolean().required('required'),
  organisations: Yup.array().when('active', {
    is: false,
    then: (schema) => schema.min(1, 'required'),
  }),
  focus: Yup.array(),
  targetAudience: Yup.array(),
});

export const createTeamSchema = Yup.object().shape({
  name: Yup.string().trim().min(2, 'tooShort').max(40, 'tooLong').required('required'),
  userIds: Yup.array().min(1, 'minimumOneCaretaker').required('required'),
});

const questionnaireInSequenceBuilderSchema = Yup.lazy((_, { context }) => {
  const selectedQuestionnaireGroup = context?.selectedQuestionnaireGroup;
  const isVersionRequired =
    // version is required if we have more than 1 questionnaire in group
    // and all of them have version property
    selectedQuestionnaireGroup?.questionnaires?.length > 1 &&
    !selectedQuestionnaireGroup?.questionnaires?.find((questionnaire) => !questionnaire.version);
  // language is required if we have more than 1 questionnaire in group
  // and all of them have language property
  const isLanguageRequired =
    selectedQuestionnaireGroup?.questionnaires?.length > 1 &&
    !selectedQuestionnaireGroup?.questionnaires.find((questionnaire) => !questionnaire.language);
  return Yup.object().shape({
    checked: Yup.boolean(),
    questionnaireVersion: Yup.string().when('checked', {
      is: true,
      then: (schema) => (isVersionRequired ? schema.required('selectAVersion') : schema.nullable()),
    }),
    questionnaireLanguage: Yup.string().when('checked', {
      is: true,
      then: (schema) => (isLanguageRequired ? schema.required('selectALanguage') : schema.nullable()),
    }),
  });
});

export const createMeasurementInActivitySidebarSchema = Yup.object().shape({
  [QuestionnaireRespondent.CLIENT]: questionnaireInSequenceBuilderSchema,
  [QuestionnaireRespondent.CARETAKER]: questionnaireInSequenceBuilderSchema,
  [QuestionnaireRespondent.AFFILIATE]: questionnaireInSequenceBuilderSchema,
});
