import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { onRequestError } from 'app/utils/showRequestErrorToast';

export function generateQueryClient(): QueryClient {
  return new QueryClient({
    mutationCache: new MutationCache({
      onError: onRequestError,
    }),
    queryCache: new QueryCache({
      onError: (error: AxiosError) => {
        console.error('Error happened in query client', error.message);
      },
    }),
    defaultOptions: {
      queries: {
        retry: (failureCount: number, error: AxiosError) =>
          (!error?.response?.status || error?.response?.status >= 500) && failureCount <= 3,
        throwOnError: (error: AxiosError) =>
          !error?.response?.status || error?.response?.status === 404 || error?.response?.status >= 500,
        refetchOnWindowFocus: false,
      },
    },
  });
}

export const queryClient = generateQueryClient();
