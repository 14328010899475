import { Portfolio } from 'app/components/app/therapist/profile/hooks/usePortfolioPage';
import { api } from 'data';
import { PortfolioSection, ArrayResponseType, AddPortfolioSectionValues } from 'data/types';

export const fetchUserPortfolio = (userId: string): Promise<Portfolio> => api.get(`/portfolios/users/${userId}`);

export const fetchUserPortfolioPublic = (token: string): Promise<Portfolio> => api.get(`/portfolios/tokens/${token}`);

export const fetchPortfolioSections = (
  portfolioId: string,
  query: string,
): Promise<ArrayResponseType<PortfolioSection>> => api.get(`/portfolios/${portfolioId}/blocks?${query}`);

export const createPortfolioSection = (
  portfolioId: string,
  values: AddPortfolioSectionValues,
): Promise<PortfolioSection> => api.post(`/portfolios/${portfolioId}/blocks`, values);

export const editPortfolioSection = (
  sectionId: string,
  values: Partial<AddPortfolioSectionValues>,
): Promise<PortfolioSection> => api.patch(`/portfolios/blocks/${sectionId}`, values);

export const deletePortfolioSection = (sectionId: string): Promise<void> =>
  api.delete(`/portfolios/blocks/${sectionId}`);

export const changePortfolioAccess = (portfolioId: string, body: { isPublic: boolean }): Promise<Portfolio> =>
  api.patch(`/portfolios/${portfolioId}`, body);

export const fetchPortfolioPublicSections = (
  portfolioToken: string,
  query: string,
): Promise<ArrayResponseType<PortfolioSection>> => api.get(`/portfolios/${portfolioToken}/blocks/public?${query}`);
