import { RecordInviteStatus } from 'data/enums';
import { ArrayResponseType, BaseFetchListParams, RecordInvite } from 'data/types';
import { recordInvitesKeys } from 'data/utils/hookKeys';
import queryString from 'qs';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { getStoredUserId } from 'data/utils/userStorage';
import { AxiosError } from 'axios';

import * as api from '../actions-query';

export interface InvitesSearchParams extends BaseFetchListParams {
  sort?: {
    [key: string]: any;
  };
  filters: {
    status?: string;
  };
}

const defaultInvitesSearchParams: InvitesSearchParams = {
  limit: 12,
  offset: 0,
  filters: {
    status: RecordInviteStatus.PENDING,
  },
};

export const useInvites = (userId: string, searchParams = defaultInvitesSearchParams) => {
  const query = queryString.stringify(searchParams);
  return useQuery<ArrayResponseType<RecordInvite>, AxiosError>({
    queryKey: recordInvitesKeys.all(userId, query),
    queryFn: () => api.fetchRecordInvites(userId, query),
  });
};

export const useInvite = (inviteId: string) =>
  useQuery<RecordInvite, AxiosError>({
    queryKey: recordInvitesKeys.details(inviteId),
    queryFn: () => api.fetchInvite(inviteId),
  });

export const usePatchInvite = (
  searchParams = defaultInvitesSearchParams,
): UseMutationResult<
  RecordInvite,
  AxiosError,
  { inviteId: string; status: RecordInviteStatus; userId?: string },
  unknown
> => {
  const queryClient = useQueryClient();
  const query = queryString.stringify(searchParams);

  const storedUserId = getStoredUserId();
  return useMutation({
    mutationFn: ({ inviteId, status, userId }) => api.patchInvite(inviteId, userId || storedUserId, status),
    onSuccess: (_, { userId }) => {
      queryClient.invalidateQueries({ queryKey: recordInvitesKeys.all(userId || storedUserId, query) });
    },
  });
};
