import { PUITheme } from '@panenco/ui';
import React from 'react';
import { TooltipContext, TooltipContextInterface } from '.';

interface Props {
  readonly theme: PUITheme;
  children: React.ReactNode;
}

export const TooltipProvider: React.FC<Props> = ({ children, theme }): JSX.Element => {
  const value: TooltipContextInterface = {
    arrowColor: theme.colors.base800,
    delayHide: 100,
    delayShow: 100,
    clickable: true,
    className: 'customTooltipTheme',
  };

  return <TooltipContext.Provider value={Object.freeze(value)}>{children}</TooltipContext.Provider>;
};
