/* eslint-disable no-underscore-dangle */
export const { MODE } = import.meta.env;

export const PROD = import.meta.env.MODE === 'production';
export const STAGING = import.meta.env.MODE === 'staging';
export const DEV = !PROD && !STAGING;

export const TEST = import.meta.env.MODE === 'test';

export const { QIT_API_BASE_URL } = import.meta.env;

export const { QIT_INTERCOM_VERIFICATION_SECRET } = import.meta.env;

export const { QIT_INTERCOM_API_KEY } = import.meta.env;

export const { QIT_SENTRY_DSN } = import.meta.env;

export const { QIT_DEV_CYCLE_SDK_KEY } = import.meta.env;

// Firebase config
export const { QIT_FIREBASE_API_KEY } = import.meta.env;
