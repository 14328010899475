import { EventTypeEnum } from 'data/enums';
import { CreateInteraction, Interaction } from 'data/types';
import { calendarKeys, interactionKeys } from 'data/utils/hookKeys';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import * as api from '../actions-query';
import { interactionTypeToQuery } from './notes';

export const useInteraction = (interactionId: string) =>
  useQuery<Interaction, AxiosError>({
    queryKey: interactionKeys.detail(interactionId),
    queryFn: () => api.fetchInteraction(interactionId),
  });

export const useCreateInteraction = (): UseMutationResult<
  Interaction,
  AxiosError,
  { trajectoryId: string; values: CreateInteraction },
  unknown
> => useMutation({ mutationFn: ({ trajectoryId, values }) => api.createInteraction(trajectoryId, values) });

export const useDeleteFileFromInteraction = (
  interactionId: string,
  eventType: EventTypeEnum,
): UseMutationResult<unknown, any, string, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (fileId) => api.deleteFile(fileId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: interactionTypeToQuery[eventType].detail(interactionId) });
    },
  });
};

export const useRemoveInteraction = (userId?: string) => {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, string, unknown>({
    mutationFn: (interactionId: string) => api.removeInteraction(interactionId),
    onSuccess: () => {
      if (userId) {
        queryClient.invalidateQueries({ queryKey: calendarKeys.eventsAll() });
      }
    },
  });
};
