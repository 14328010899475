enum ButtonText {
  SELECT = 'select',
  UNSELECT = 'unselect',
  UPGRADE = 'upgrade',
  CURRENT = 'currentPlan',
}

export const getButtonText = (isSelected: boolean, isUpgrade: boolean, isCheaper: boolean) => {
  if (isSelected && isUpgrade) return ButtonText.CURRENT;
  if (isUpgrade && !isCheaper) return ButtonText.UPGRADE;
  if (isSelected) return ButtonText.UNSELECT;
  return ButtonText.SELECT;
};
