import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useProfile } from 'data/hooks';
import { OrganizationStatus, RoleEnum } from 'data/enums';
import { differenceInDays } from 'date-fns';
import { TFunction } from 'i18next';
import { settingsBillingPath, subscriptionsPath } from 'app/constants/url/therapist';
import { useHistory } from 'react-router';
import { useHasRole } from '../hooks';
import { FreeTrialContext, FreeTrialContextInterface } from '.';

export interface FreeTrialProviderProps {
  children: ReactNode;
}

const getBannerText = (
  t: TFunction<'translation', undefined>,
  isOrganisationStatusInactive: boolean,
  isOrganisationStatusActive: boolean,
  isOrganisationStatusTrial: boolean,
  isProfileActive: boolean,
  isOrganisationAdmin: boolean,
  isTrialEnded: boolean,
  days: number,
) => {
  if (isOrganisationStatusInactive)
    return isOrganisationAdmin ? t('inactiveOrganisationTitleOrgAdmin') : t('inactiveOrganisationTitleTherapist');
  if (isOrganisationStatusActive && !isProfileActive)
    return isOrganisationAdmin
      ? t('yourOrganisationDoesntHavePaidPlanOrgAdmin')
      : t('yourOrganisationDoesntHavePaidPlanTherapist');
  if (isOrganisationStatusTrial) {
    if (isTrialEnded)
      return isOrganisationAdmin
        ? t('organisationTrialEndedTitleOrgAdmin')
        : t('organisationTrialEndedTitleOrgTherapist');
    if (isProfileActive && days <= 7) {
      if (days === 0)
        return isOrganisationAdmin
          ? t('freeTrialWillExpireTodayOrgAdmin', { count: days })
          : t('freeTrialWillExpireTodayTherapist', { count: days });
      return isOrganisationAdmin
        ? t('freeTrialWillExpireSoonOrgAdmin', { count: days })
        : t('freeTrialWillExpireSoonTherapist', { count: days });
    }
    if (!isProfileActive)
      return isOrganisationAdmin
        ? t('yourOrganisationDoesntHavePaidPlanOrgAdmin')
        : t('yourOrganisationDoesntHavePaidPlanTherapist');
  }
  return null;
};

const getBannerButtonText = (
  t: TFunction<'translation', undefined>,
  isOrganisationAdmin: boolean,
  isOrganisationStatusActive: boolean,
  isOrganisationStatusTrial: boolean,
  isProfileActive: boolean,
) => {
  if (isOrganisationAdmin && !isProfileActive && (isOrganisationStatusActive || isOrganisationStatusTrial)) {
    return t('billingSettings');
  }

  return isOrganisationAdmin ? t('selectPlan') : t('contactOrganisationAdmin');
};

const FreeTrialProvider = ({ children }: FreeTrialProviderProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { profile } = useProfile();
  const isOrganisationAdmin = useHasRole(RoleEnum.ROLE_ORGANISATION_ADMIN);

  const isOrganisationStatusActive = useMemo(
    () => profile?.organisation?.status === OrganizationStatus.ACTIVE,
    [profile?.organisation?.status],
  );
  const isOrganisationStatusTrial = useMemo(
    () => profile?.organisation?.status === OrganizationStatus.TRIAL,
    [profile?.organisation?.status],
  );
  const isOrganisationStatusInactive = useMemo(
    () => profile?.organisation?.status === OrganizationStatus.INACTIVE,
    [profile?.organisation?.status],
  );

  const isProfileActive = useMemo(() => profile.isActive, [profile.isActive]);

  const isTrialEnded = useMemo(
    () => new Date() > new Date(profile?.organisation?.trialEndAt || new Date()),
    [profile?.organisation?.trialEndAt],
  );

  const daysTrialAndIn = useMemo(
    () => differenceInDays(new Date(profile?.organisation?.trialEndAt || new Date()), new Date()),
    [profile?.organisation?.trialEndAt],
  );

  const disableButtons = useMemo(
    () =>
      isOrganisationStatusInactive ||
      (isOrganisationStatusActive && !isProfileActive) ||
      (isOrganisationStatusTrial && (isTrialEnded || (!isTrialEnded && !isProfileActive))),
    [
      isOrganisationStatusInactive,
      isOrganisationStatusActive,
      isProfileActive,
      isTrialEnded,
      isOrganisationStatusTrial,
    ],
  );

  const bannerText = useMemo(
    () =>
      getBannerText(
        t,
        isOrganisationStatusInactive,
        isOrganisationStatusActive,
        isOrganisationStatusTrial,
        isProfileActive,
        isOrganisationAdmin,
        isTrialEnded,
        daysTrialAndIn,
      ),
    [
      t,
      isOrganisationStatusInactive,
      isOrganisationStatusActive,
      isOrganisationStatusTrial,
      isProfileActive,
      isOrganisationAdmin,
      isTrialEnded,
      daysTrialAndIn,
    ],
  );

  const bannerButtonText = useMemo(
    () =>
      getBannerButtonText(
        t,
        isOrganisationAdmin,
        isOrganisationStatusActive,
        isOrganisationStatusTrial,
        isProfileActive,
      ),
    [t, isOrganisationAdmin, isOrganisationStatusActive, isOrganisationStatusTrial, isProfileActive],
  );

  const bannerHandleClick = (adminEmail: string) => {
    if (isOrganisationAdmin && !isProfileActive && (isOrganisationStatusActive || isOrganisationStatusTrial)) {
      history.push(settingsBillingPath);
    } else if (isOrganisationAdmin) {
      history.push(subscriptionsPath);
    } else {
      window.location.href = `mailto:${adminEmail}`;
    }
  };

  const value: FreeTrialContextInterface = useMemo(
    () => ({
      bannerText,
      bannerButtonText,
      organisationId: profile?.organisation?.id || '',
      disableButtons,
      bannerHandleClick,
      isOrganisationAdmin,
      isOrganisationStatusActive,
      isOrganisationStatusTrial,
      isOrganisationStatusInactive,
      paymentType: profile?.organisation?.paymentType || null,
    }),
    [
      bannerText,
      disableButtons,
      bannerButtonText,
      bannerHandleClick,
      profile?.organisation?.id,
      isOrganisationAdmin,
      isOrganisationStatusActive,
      isOrganisationStatusTrial,
      isOrganisationStatusInactive,
      profile?.organisation?.paymentType,
    ],
  );

  return <FreeTrialContext.Provider value={value}>{children}</FreeTrialContext.Provider>;
};

export default FreeTrialProvider;
