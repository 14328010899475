import { Addon, IOrganizationStripeSubscriptions, Price, PriceInterval, AddonPrice } from 'data/types';

export const getCurrentPrice = (currentPriceInterval: PriceInterval, subscriptionPrices: (Price | AddonPrice)[]) =>
  subscriptionPrices.find((price) => price.interval === currentPriceInterval);

export const changeSelectedSubscriptionOnInterval = (
  selectedSubscriptionPrice: { id: string; amount: number },
  priceInterval: PriceInterval,
  setSelectedSubscription: (value: { id: string; amount: number }) => void,
  subscriptions?: IOrganizationStripeSubscriptions,
) => {
  if (selectedSubscriptionPrice.id) {
    // find selected subscription object using id of it price
    const selectedSubscription = subscriptions?.products.find((product) =>
      product.prices?.find((price) => price.id === selectedSubscriptionPrice.id),
    );
    // find selected subscription price with active interval
    const selectedSubscriptionPriceWithInterval = selectedSubscription?.prices.find(
      (price) => price.interval === priceInterval,
    );

    // select correct price with active interval
    if (selectedSubscriptionPriceWithInterval) {
      setSelectedSubscription({
        id: selectedSubscriptionPriceWithInterval.id,
        amount: selectedSubscriptionPriceWithInterval.amount,
      });
    }
  }
};

export const changeSelectedAddonsOnInterval = (
  selectedAddons: {
    id: string;
    amount: number;
  }[],
  priceInterval: PriceInterval,
  setSelectedAddons: (value: { id: string; amount: number }[]) => void,
  addons?: {
    products: Addon[];
  },
) => {
  if (selectedAddons.length) {
    // find selected addons
    const selectedAddonsObjects = addons?.products
      .map(
        (addon) => addon.prices.find((price) => selectedAddons.some((selected) => selected.id === price.id)) && addon,
      )
      .filter((addon) => addon);

    // find selected addons prices with active interval
    const selectedAddonsPricesWithIntervals = selectedAddonsObjects?.map((addon) =>
      addon?.prices.find((price) => price.interval === priceInterval),
    );

    setSelectedAddons(
      selectedAddonsPricesWithIntervals?.map((price) => ({ id: price?.id || '', amount: price?.amount || 0 })) || [],
    );
  }
};
