import { baseAdminPath } from 'app/constants/url/admin';
import { baseClientPath } from 'app/constants/url/patient';
import { basePath } from 'app/constants/url/shared';
import { TitleAnchor } from 'app/components/core/title';
import { baseTherapistPath } from 'app/constants/url/therapist';
import { Helmet } from 'react-helmet';
import React, { Suspense, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';

import { cookiesConsentKey, CookieTypes, initializeReactGA } from 'app/components/core/cookie-popup/utils';
import ProtectedRoute from 'app/components/shared/protected/ProtectedRoute';
import { RoleEnum } from 'data/enums';
import { useInjectStylesFirst } from '@panenco/ui';
import { PROD, TEST } from 'app/constants/env';
import { NotificationContainer } from 'ui';
import FreeTrialProvider from 'app/utils/context/free-trial-provider';
import Loader from 'app/components/core/preloader';
import { lazyRetry } from 'app/components/shared/lazy-retry';
import { ContentSecurityPolicy } from 'app/components/core';
import DigitalAddonProvider from 'app/utils/context/digital-addon-provider';

const Admin = React.lazy(() => lazyRetry(() => import('./admin'), 'admin'));
const Therapist = React.lazy(() => lazyRetry(() => import('./therapist'), 'therapist'));
const Client = React.lazy(() => lazyRetry(() => import('./patient'), 'patient'));
const Shared = React.lazy(() => lazyRetry(() => import('./shared'), 'shared'));

const Qit = () => {
  useInjectStylesFirst();
  const [showToastBelow, setShowToastBelow] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const cookies = Cookies.get(cookiesConsentKey);
    const isAnalyticsEnabled = cookies && JSON.parse(cookies)[CookieTypes.analytics] === 1;

    if (PROD && isAnalyticsEnabled) {
      initializeReactGA();
    }
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <Helmet>
        <meta name="description" content={t('appDescription')} />
      </Helmet>
      {!TEST && <ContentSecurityPolicy />}
      <TitleAnchor tab="appTitle" />
      <NotificationContainer showToastBelow={showToastBelow} />
      <Switch>
        <ProtectedRoute
          path={baseTherapistPath}
          allowedRoles={[RoleEnum.ROLE_ORGANISATION_ADMIN, RoleEnum.ROLE_THERAPIST]}
        >
          <FreeTrialProvider>
            <DigitalAddonProvider>
              <Therapist setShowToastBelow={setShowToastBelow} />
            </DigitalAddonProvider>
          </FreeTrialProvider>
        </ProtectedRoute>
        <ProtectedRoute path={baseClientPath} allowedRoles={[RoleEnum.ROLE_CLIENT, RoleEnum.ROLE_CLIENT_SYSTEM]}>
          <Client />
        </ProtectedRoute>
        <ProtectedRoute path={baseAdminPath} allowedRoles={[RoleEnum.ROLE_SUPER_ADMIN]}>
          <Admin />
        </ProtectedRoute>
        <Route path={basePath} component={Shared} />
      </Switch>
    </Suspense>
  );
};

export default Sentry.withProfiler(Qit);
