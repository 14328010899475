import { organizationKeys, organizationMembersKeys } from 'data/utils/hookKeys';
import queryString from 'qs';
import {
  SortDirection,
  OrganizationSortKeys,
  OrganizationStatus,
  OrganizationMembersSortKeys,
  IntakePermissions,
} from 'data/enums';
import {
  ArrayResponseType,
  BaseFetchListParams,
  Organization,
  EditOrganizationFields,
  OrgMember,
  BillingPortalFields,
  CreateOrganizationFields,
  InviteMemberFields,
  IOrganizationAdminDetails,
  IOrganizationStripeSubscriptions,
  IOrganizationStripeLink,
  DocumentFile,
  CreateNewOrganization,
  Addon,
  Products,
  OrganisationPrices,
  OrgTeamMember,
} from 'data/types';
import { AxiosError } from 'axios';
import { useQuery, UseMutationResult, useQueryClient, useMutation } from '@tanstack/react-query';
import * as api from '../actions-query';

export interface IOrganizationsSearchParams extends BaseFetchListParams {
  sort?: {
    [key in OrganizationSortKeys]?: SortDirection;
  };
  filters: {
    search?: string | null;
    status?: OrganizationStatus;
  };
}

export const defaultOrgSearchParams: IOrganizationsSearchParams = {
  sort: {
    [OrganizationSortKeys.ORGANIZATION_NAME]: SortDirection.asc,
  },
  limit: 12,
  offset: 0,
  filters: {
    status: OrganizationStatus.ACTIVE,
  },
};

export interface OrganizationMembersSearchParams extends BaseFetchListParams {
  filters?: {
    excludedFromBilling?: boolean | string;
    isActive?: number;
    enabled?: number;
    intakePermission?: IntakePermissions;
  };
  sort?: {
    [key in OrganizationMembersSortKeys]?: SortDirection;
  };
}

export const defaultOrgMembersSearchParams: OrganizationMembersSearchParams = {
  limit: 12,
  offset: 0,
};

export const useOrganizations = (searchParams: IOrganizationsSearchParams) => {
  const query = queryString.stringify(searchParams);
  return useQuery<ArrayResponseType<Organization>, AxiosError>({
    queryKey: organizationKeys.all(query),
    queryFn: () => api.fetchOrganizations(query),
    staleTime: 0,
    gcTime: 300000, // 5 minutes
    refetchInterval: 60000, // 60 seconds
  });
};

export const useOrganizationMembers = (organizationId?: string, searchParams = defaultOrgMembersSearchParams) => {
  const query = queryString.stringify(searchParams);
  return useQuery<ArrayResponseType<OrgMember>, AxiosError>({
    queryKey: organizationMembersKeys.query(organizationId, query),
    queryFn: () => api.fetchOrganizationMembers(organizationId, query),
    enabled: !!organizationId,
  });
};

export const useOrganizationTeams = (organizationId?: string, searchParams = defaultOrgMembersSearchParams) => {
  const query = queryString.stringify(searchParams);
  return useQuery<ArrayResponseType<OrgTeamMember>, AxiosError>({
    queryKey: organizationMembersKeys.teams(organizationId, query),
    queryFn: () => api.fetchOrganizationTeams(organizationId, query),
    enabled: !!organizationId,
  });
};

export const useOrganizationMembersSimpleList = (
  organizationId?: string,
  searchParams = defaultOrgMembersSearchParams,
) => {
  const query = queryString.stringify(searchParams);
  return useQuery<ArrayResponseType<OrgMember>, AxiosError>({
    queryKey: organizationMembersKeys.query(organizationId, query),
    queryFn: () => api.fetchOrganizationMembersSimpleList(organizationId, query),
    enabled: !!organizationId,
  });
};

export const useOrganization = (organizationId: string | undefined | null) =>
  useQuery<Organization, AxiosError>({
    queryKey: organizationKeys.detail(organizationId),
    queryFn: () => api.fetchOrganization(organizationId),
    enabled: !!organizationId,
  });

export const useOrganizationBillingPortal = (organizationId: string) =>
  useQuery<BillingPortalFields, AxiosError>({
    queryKey: organizationKeys.billingPortal(organizationId),
    queryFn: () => api.fetchBillingPortal(organizationId),
    enabled: !!organizationId,
  });

export const useCreateOrganization = (): UseMutationResult<
  unknown,
  any,
  { fields: CreateOrganizationFields },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ fields }) => api.createOrganization(fields),
    onSuccess: () => {
      const query = queryString.stringify(defaultOrgSearchParams);
      queryClient.invalidateQueries({ queryKey: organizationKeys.all(query) });
    },
  });
};

export const useEditOrganization = (): // searchParams?: IOrganizationsSearchParams,
UseMutationResult<unknown, any, { organizationId: string; fields: EditOrganizationFields }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ organizationId, fields }) => api.editOrganization(organizationId, fields),
    onSuccess: (data, variables) => {
      // const query = queryString.stringify(searchParams, );
      queryClient.invalidateQueries({ queryKey: organizationKeys.all('') });
      queryClient.invalidateQueries({ queryKey: organizationKeys.detail(variables.organizationId) });
      queryClient.invalidateQueries({ queryKey: organizationKeys.logo(data?.logo?.id) });
    },
  });
};

export const useEditOrganizationOptimistically = (): // searchParams?: IOrganizationsSearchParams,
UseMutationResult<unknown, any, { organizationId: string; fields: EditOrganizationFields }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ organizationId, fields }) => api.editOrganization(organizationId, fields),
    onMutate: async ({ organizationId, fields }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: organizationKeys.detail(organizationId) });

      // Snapshot the previous value
      const previousOrganizationState = queryClient.getQueryData(organizationKeys.detail(organizationId));

      // Optimistically update to the new value
      if (previousOrganizationState)
        queryClient.setQueryData(organizationKeys.detail(organizationId), { ...previousOrganizationState, ...fields });

      // Return a context object with the snapshotted value
      return { previousOrganizationState };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (_, data, context) => {
      queryClient.setQueryData(organizationKeys.detail(data.organizationId), context?.previousOrganizationState);
    },
    // Always refetch after error or success:
    onSettled: (organization) => {
      queryClient.invalidateQueries({ queryKey: organizationKeys.all('') });
      queryClient.invalidateQueries({ queryKey: organizationKeys.detail(organization?.id) });
      queryClient.invalidateQueries({ queryKey: organizationKeys.logo(organization?.id, organization?.logo?.id) });
    },
  });
};

export const useDeleteOrganization = (): UseMutationResult<unknown, any, string, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (organizationId) => api.deleteOrganization(organizationId),
    onSuccess: () => {
      const query = queryString.stringify(defaultOrgSearchParams);
      queryClient.invalidateQueries({ queryKey: organizationKeys.all(query) });
    },
  });
};

export const useAddMember = (): UseMutationResult<
  unknown,
  AxiosError,
  { organizationId: string; fields: InviteMemberFields },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ organizationId, fields }) => api.addMember(organizationId, fields),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: organizationMembersKeys.all() });
    },
  });
};

export const useOrganizationAdmin = (organizationId: string | null) =>
  useQuery<IOrganizationAdminDetails, AxiosError>({
    queryKey: organizationKeys.organizationAdmin(organizationId),
    queryFn: () => api.fetchOrganizationAdminDetails(organizationId),
    enabled: !!organizationId,
  });

export const useOrganizationStripeLink = (organizationId: string | null) =>
  useQuery<IOrganizationStripeLink, AxiosError>({
    queryKey: organizationKeys.stripeLink(organizationId),
    queryFn: () => api.fetchOrganizationStripeLink(organizationId),
    enabled: !!organizationId,
  });

export const useOrganizationsStripeSubscriptions = () =>
  useQuery<IOrganizationStripeSubscriptions, AxiosError>({
    queryKey: organizationKeys.stripeSubscriptions(),
    queryFn: () => api.fetchOrganizationsStripeSubscriptions(),
  });

export const useUpgradeOrganisationSubscription = (): UseMutationResult<
  void,
  AxiosError,
  { organisationId: string; priceId: string },
  unknown
> =>
  useMutation({
    mutationFn: ({ organisationId, priceId }) => api.updateOrganisationSubscription(organisationId, priceId),
  });

export const useOrganisationAddonPrice = ({ organisationId, priceId }: { organisationId: string; priceId: string }) =>
  useQuery<any, AxiosError>({
    queryKey: organizationKeys.organisationAddonPrice(organisationId, priceId),
    queryFn: () => api.fetchOrganisationAddonPrice(organisationId, priceId),
  });

export const useOrganizationSubscriptionPortal = (): UseMutationResult<
  unknown,
  AxiosError,
  { organizationId: string; prices: string[] },
  unknown
> =>
  useMutation({
    mutationFn: ({ organizationId, prices }) => api.fetchOrganizationSubscriptionPortalLink(organizationId, prices),
  });

export const useOrganizationLogo = (orgId: string, fileId: string) =>
  useQuery<DocumentFile, AxiosError>({
    queryKey: organizationKeys.logo(orgId, fileId),
    queryFn: () => api.fetchOrganizationLogo(fileId, orgId),
    enabled: !!fileId,
  });

export const useDeleteOrganizationLogo = (): UseMutationResult<
  unknown,
  void,
  { orgId: string; fileId: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ orgId, fileId }) => api.deleteOrganizationLogo(fileId),
    onSuccess: (_, { orgId, fileId }) => {
      queryClient.invalidateQueries({ queryKey: organizationKeys.detail(orgId) });
    },
  });
};

export const useCreateNewOrganization = (): UseMutationResult<
  Organization,
  AxiosError,
  CreateNewOrganization,
  unknown
> => {
  const query = queryString.stringify(defaultOrgSearchParams);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (fields) => api.createNewOrganization(fields),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: organizationKeys.all(query) }),
  });
};

export const useAddons = (disabled?: boolean) =>
  useQuery<{ products: Addon[] }, AxiosError>({
    queryKey: organizationKeys.addons(),
    queryFn: () => api.fetchAddons(),
    enabled: !disabled,
  });

export const useOrganisationProducts = (organisationId: string, disabled?: boolean) =>
  useQuery<Products, AxiosError>({
    queryKey: organizationKeys.organisationAddons(organisationId),
    queryFn: () => api.fetchOrganisationProducts(organisationId),
    enabled: !!organisationId && !disabled,
  });

export const useAddOrganisationAddon = (): UseMutationResult<
  void,
  AxiosError,
  { organisationId: string; prices: string[] },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ organisationId, prices }) => api.addOrganisationAddon(organisationId, { prices }),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: organizationKeys.organisationAddons(variables.organisationId) });
    },
  });
};

export const useRemoveOrganisationAddon = (): UseMutationResult<
  void,
  AxiosError,
  { organisationId: string; priceId: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ organisationId, priceId }) => api.removeOrganisationAddon(organisationId, priceId),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: organizationKeys.organisationAddons(variables.organisationId) });
    },
  });
};

export const useOrganisationPrices = (organisationId: string) =>
  useQuery<OrganisationPrices, AxiosError>({
    queryKey: organizationKeys.organisationPrices(organisationId),
    queryFn: () => api.fetchOrganisationPrices(organisationId),
    enabled: !!organisationId,
  });

export const useUpdateOrganisationMember = (
  organisationId: string,
  searchParams = defaultOrgMembersSearchParams,
): UseMutationResult<OrgMember, AxiosError, { memberId: string; body: Partial<OrgTeamMember> }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ memberId, body }) => api.updateOrganisationMember(organisationId, memberId, body),
    onSuccess: () => {
      const query = queryString.stringify(searchParams);
      queryClient.invalidateQueries({ queryKey: organizationMembersKeys.teams(organisationId, query) });
    },
  });
};
