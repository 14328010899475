import React, { ReactElement, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cx from 'classnames';
import { PriceInterval, Product } from 'data/types';
import { useFreeTrialContext } from 'app/utils/hooks';
import { TFunction } from 'i18next';
import { PUIButton, PUIText, appTheme } from 'ui';
import { convertCentToEuro } from 'app/utils';

import { getCurrentPrice } from '../../utils';
import { getButtonText } from './utils';
import s from './styles.module.scss';

export enum PlansEnum {
  STARTER = 'starter',
  STANDARD = 'standard',
  PREMIUM = 'premium',
}

const plansArray = [PlansEnum.STARTER, PlansEnum.STANDARD, PlansEnum.PREMIUM];

const getPlanTyping = (
  planIndex: number,
  t: TFunction<'translation', undefined>,
): { title: string; description: string; benefits: { [key: string]: string } } => {
  const text: string[] = t('plans', { returnObjects: true });
  return text[plansArray[planIndex]];
};

export interface ProductCardProps {
  product: Product;
  selectedSubscription: { id: string; amount: number };
  planIndex: number;
  isLoading: boolean;
  priceInterval: PriceInterval;
  handleSelectSubscription: ({ id, amount, name }: { id: string; amount: number; name: string }) => void;
  isUpgradeFlow: boolean;
}

const PlanCard = ({
  product,
  selectedSubscription,
  planIndex,
  isLoading,
  priceInterval,
  handleSelectSubscription,
  isUpgradeFlow,
}: ProductCardProps): ReactElement => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { organisationId } = useFreeTrialContext();

  const isDesirablePlan = planIndex === 1;
  const isSelected = selectedSubscription.id === String(getCurrentPrice(priceInterval, product.prices)?.id);
  const isCheaper = Number(getCurrentPrice(priceInterval, product.prices)?.amount) < selectedSubscription.amount;
  const isSelectedWhileUpgrading = isSelected && isUpgradeFlow;
  const typing = useMemo(() => getPlanTyping(planIndex, t), [planIndex, language]);

  const onSelectSubscription = () =>
    handleSelectSubscription({
      id: String(getCurrentPrice(priceInterval, product.prices)?.id),
      amount: getCurrentPrice(priceInterval, product.prices)?.amount || 0,
      name: product.name,
    });

  return (
    <div className={cx(s.card, isSelected && s.cardSelected, isSelectedWhileUpgrading && s.cardSelectedUpgrade)}>
      {isDesirablePlan ? (
        <div className={s.cardLabel}>
          <PUIText
            color={appTheme.colors.base100}
            size={appTheme.typography.sizes.m}
            weight={appTheme.typography.weights.medium}
          >
            {t('qitRecommends')}
          </PUIText>
        </div>
      ) : null}
      <div className={s.cardHeader}>
        <PUIText
          color={appTheme.colors.base900}
          weight={appTheme.typography.weights.medium}
          className={s.cardHeaderTitle}
        >
          {typing.title}
        </PUIText>
        <PUIText
          color={appTheme.colors.base800}
          weight={appTheme.typography.weights.medium}
          size={appTheme.typography.sizes.xs}
        >
          {typing.description}
        </PUIText>
      </div>
      <div className={s.cardPrice}>
        <PUIText
          color={appTheme.colors.base900}
          weight={appTheme.typography.weights.light}
          className={s.cardPriceAmount}
        >
          €{convertCentToEuro(getCurrentPrice(priceInterval, product.prices)?.amount || 0)}
        </PUIText>
        <PUIText
          className={s.cardPriceLabel}
          color={appTheme.colors.base900}
          weight={appTheme.typography.weights.light}
          size={appTheme.typography.sizes.xs}
        >
          <Trans i18nKey="perInterval" values={{ interval: t(`priceInterval.${priceInterval}`) }} />
        </PUIText>
      </div>
      {organisationId ? (
        <PUIButton
          variant={isSelected ? 'outlined' : 'contained'}
          disabled={isLoading || isSelectedWhileUpgrading}
          color="secondary"
          isLoading={isLoading}
          className={cx(s.cardButton, isSelectedWhileUpgrading && s.cardButtonCurrent)}
          onClick={onSelectSubscription}
        >
          {t(getButtonText(isSelected, isUpgradeFlow, isCheaper))}
        </PUIButton>
      ) : null}
      <ul className={s.cardBenefits}>
        {Object.values(typing.benefits).map((benefit, index) => (
          <PUIText
            key={benefit}
            color={appTheme.colors.base900}
            weight={!index ? appTheme.typography.weights.semiBold : appTheme.typography.weights.light}
            size={appTheme.typography.sizes.m}
          >
            {benefit}
          </PUIText>
        ))}
      </ul>
    </div>
  );
};

export default PlanCard;
