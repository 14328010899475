import { RecordsView, ClientSortKeys, CalendarViewType, SortDirection } from 'data/enums';

const appName = 'qit';

const booleanStrings = ['true', 'false'];

const rowsPerPageKeys = {
  [RecordsView.CARDS]: 'recordCards',
  [RecordsView.LIST]: 'recordList',
  recordAffiliates: 'recordAffiliates',
  recordDocuments: 'recordDocuments',
};

const paths = {
  recordsOverviewType: (): string => [appName, 'recordsOverviewType'].join('/'),
  recordsSortPreference: (): string => [appName, 'sortPreference'].join('/'),
  sortDirectionPreference: (): string => [appName, 'sortDirectionPreference'].join('/'),
  rowsPerPagePreference: (type: string) => [appName, type, 'rowsPerPage'].join('/'),
  shownCalendarsPreference: (): string => [appName, 'shownCalendars'].join('/'),
  calendarShowInactiveTraj: (): string => [appName, 'calendarShowInactiveTraj'].join('/'),
  calendarViewPreference: (): string => [appName, 'calendarView'].join('/'),
  showSessionDocuments: (): string => [appName, 'showSessionDocuments'].join('/'),
  isCompareAnswers: (): string => [appName, 'isCompareAnswers'].join('/'),
  showConventionedClients: (): string => [appName, 'isShowConventioned'].join('/'),
  hidePortfolioBanner: (): string => [appName, 'isPortfolioBannerHidden'].join('/'),
  folder: (): string => [appName, 'folder'].join('/'),
  lastSessionDuration: (): string => [appName, 'lastSessionDuration'].join('/'),
  draftMessage: (roomId: string): string => [appName, roomId, 'draftMessage'].join('/'),
  intakeAssigneesFilter: (): string => [appName, 'intakeAssigneesFilter'].join('/'),
  intakeLabelFilter: (): string => [appName, 'intakeLabelFilter'].join('/'),
};

const getRecordsOverviewType = (): RecordsView =>
  (localStorage.getItem(paths.recordsOverviewType()) as RecordsView) || RecordsView.CARDS;
const setRecordsOverviewType = (newValue: RecordsView): void =>
  localStorage.setItem(paths.recordsOverviewType(), newValue);

const getSortDirectionPreference = (): SortDirection =>
  (localStorage.getItem(paths.sortDirectionPreference()) as SortDirection) || SortDirection.asc;
const setSortDirectionPreference = (newValue: SortDirection): void =>
  localStorage.setItem(paths.sortDirectionPreference(), newValue);

const getCompareAnswers = (): boolean => {
  const localStorageItem = localStorage.getItem(paths.isCompareAnswers()) || '{}';
  const data = !booleanStrings.includes(localStorageItem) ? 'false' : localStorageItem;
  return JSON.parse(data);
};
const setCompareAnswers = (newValue: boolean): void => {
  localStorage.setItem(paths.isCompareAnswers(), newValue.toString());
};

const getRowsPerPagePreference = (key: string): number =>
  Number(localStorage.getItem(paths.rowsPerPagePreference(key))) || 12;
const setRowsPerPagePreference = (key: string, perPage: number) =>
  localStorage.setItem(paths.rowsPerPagePreference(key), perPage.toString());

const getSortPreference = () => {
  let preference = localStorage.getItem(paths.recordsSortPreference());
  if (!Object.values(ClientSortKeys).some((v) => v === preference)) preference = ClientSortKeys.START_DATE;
  return (preference as ClientSortKeys) || ClientSortKeys.LAST_NAME;
};
const setSortPreference = (key: ClientSortKeys) => localStorage.setItem(paths.recordsSortPreference(), key);

const getCalendarShowInactiveTraj = (): boolean => {
  const localStorageItem = localStorage.getItem(paths.calendarShowInactiveTraj()) || '{}';

  const data = !booleanStrings.includes(localStorageItem) ? 'false' : localStorageItem;

  return JSON.parse(data);
};

const setCalendarShowInactiveTraj = (newValue: boolean): void =>
  localStorage.setItem(paths.calendarShowInactiveTraj(), newValue.toString());

const getShowSessionDocuments = (): boolean => {
  const localStorageItem = localStorage.getItem(paths.showSessionDocuments()) || '{}';

  const data = !booleanStrings.includes(localStorageItem) ? 'false' : localStorageItem;
  return JSON.parse(data);
};
const setShowSessionDocuments = (newValue: boolean): void =>
  localStorage.setItem(paths.showSessionDocuments(), newValue.toString());

const getShownCalendarsPreference = (): string[] =>
  localStorage.getItem(paths.shownCalendarsPreference())?.split(',') || [];
const setShownCalendarsPreference = (calendarsIds: string[]): void =>
  localStorage.setItem(paths.shownCalendarsPreference(), [calendarsIds].toString());

const getCalendarViewPreference = (): CalendarViewType =>
  (localStorage.getItem(paths.calendarViewPreference()) as CalendarViewType) || CalendarViewType.week;
const setCalendarViewPreference = (type: CalendarViewType): void =>
  localStorage.setItem(paths.calendarViewPreference(), type);

const getShowConventionedClients = (): string | undefined =>
  localStorage.getItem(paths.showConventionedClients()) || undefined;
const setShowConventionedClients = (newValue?: string): void =>
  localStorage.setItem(paths.showConventionedClients(), newValue || '');

const getIsPortfolioBannerHidden = (): boolean => Boolean(localStorage.getItem(paths.hidePortfolioBanner()));
const setIsPortfolioBannerHidden = (value: boolean) =>
  localStorage.setItem(paths.hidePortfolioBanner(), value.toString());

const getFolder = (): string | undefined => localStorage.getItem(paths.folder()) || undefined;
const setFolder = (folder?: string): void => localStorage.setItem(paths.folder(), folder || '');

const getLastSessionDuration = (): string | null => localStorage.getItem(paths.lastSessionDuration());
const setLastSessionDuration = (duration: number): void =>
  localStorage.setItem(paths.lastSessionDuration(), String(duration));

const getDraftMessage = (roomId: string): string | undefined =>
  localStorage.getItem(paths.draftMessage(roomId)) || undefined;
const setDraftMessage = (roomId: string, draftMessage: string): void =>
  localStorage.setItem(paths.draftMessage(roomId), draftMessage);
const removeDraftMessage = (roomId: string): void => localStorage.removeItem(paths.draftMessage(roomId));

const getIntakeAssigneesFilter = (): string | undefined =>
  localStorage.getItem(paths.intakeAssigneesFilter()) || undefined;
const setIntakeAssigneesFilter = (newValue?: string): void =>
  localStorage.setItem(paths.intakeAssigneesFilter(), newValue || '');

const getIntakeFolderFilter = (): string | undefined => localStorage.getItem(paths.intakeLabelFilter()) || undefined;
const setIntakeFolderFilter = (newValue: string): void => localStorage.setItem(paths.intakeLabelFilter(), newValue);

export default {
  getRecordsOverviewType,
  setRecordsOverviewType,

  getRowsPerPagePreference,
  setRowsPerPagePreference,
  rowsPerPageKeys,

  getSortPreference,
  setSortPreference,

  getSortDirectionPreference,
  setSortDirectionPreference,

  getCalendarShowInactiveTraj,
  setCalendarShowInactiveTraj,

  getShowSessionDocuments,
  setShowSessionDocuments,

  getCompareAnswers,
  setCompareAnswers,

  getCalendarViewPreference,
  setCalendarViewPreference,

  getShowConventionedClients,
  setShowConventionedClients,

  getShownCalendarsPreference,
  setShownCalendarsPreference,

  getIsPortfolioBannerHidden,
  setIsPortfolioBannerHidden,

  getFolder,
  setFolder,

  getLastSessionDuration,
  setLastSessionDuration,

  getDraftMessage,
  setDraftMessage,
  removeDraftMessage,

  getIntakeAssigneesFilter,
  setIntakeAssigneesFilter,

  getIntakeFolderFilter,
  setIntakeFolderFilter,
};
