import {
  CreateInteractionActivity,
  EditSessionFormValues,
  InteractionActivityEntity,
  ExpandedInteractionActivity,
  ArrayResponseType,
  Activity,
} from 'data/types';
import { useTranslation } from 'react-i18next';
import {
  interactionActivitiesKeys,
  trajectoryKeys,
  calendarKeys,
  activitiesKeys,
  notesKeys,
} from 'data/utils/hookKeys';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import saveBlob from 'data/utils/actions';
import { useTrackAnalyticsEvent } from 'app/utils/hooks/useTrackAnalyticsEvent';
import queryString from 'qs';
import { SortDirection, SessionsSortNames, ActivityType, EventStatuses, NotesType, AnalyticsEvent } from 'data/enums';
import { AxiosError } from 'axios';
import { addYears } from 'date-fns';

import { useRemoveInteraction } from './interaction';
import { activeTrajectoriesFetchParams } from '.';
import * as api from '../actions-query';

export interface InteractionActivitiesFilters {
  offset: number;
  limit: number;
  sort: {
    [key in SessionsSortNames]?: SortDirection;
  };
  filters?: Record<string, any>;
  startDateFrom?: string;
  startDateTo?: string;
}

export const defaultRangeStartDate = addYears(new Date(), -10);
export const defaultRangeEndDate = addYears(new Date(), 10);

export interface InteractionActivitiesReportFilters {
  sort: {
    [key in SessionsSortNames]?: SortDirection;
  };
  startDateFrom?: string;
  startDateTo?: string;
}

const defaultInteractionActivitiesFilters: InteractionActivitiesFilters = {
  offset: 0,
  limit: 12,
  sort: {
    [SessionsSortNames.name]: SortDirection.asc,
  },
  startDateFrom: defaultRangeStartDate.toString(),
  startDateTo: defaultRangeEndDate.toString(),
};

const defaultInteractionActivitiesReportFilters: InteractionActivitiesReportFilters = {
  sort: {
    [SessionsSortNames.name]: SortDirection.asc,
  },
};

export const useInteractionActivities = (
  filters: InteractionActivitiesFilters = defaultInteractionActivitiesFilters,
) => {
  const query = queryString.stringify(filters);
  return useQuery<ArrayResponseType<ExpandedInteractionActivity>, AxiosError>({
    queryKey: interactionActivitiesKeys.list(query),
    queryFn: () => api.fetchInteractionActivities(query),
  });
};

export const useInteractionActivitiesReport = (
  filters: InteractionActivitiesReportFilters = defaultInteractionActivitiesReportFilters,
): UseMutationResult<Blob, AxiosError, unknown, unknown> => {
  const {
    i18n: { language },
  } = useTranslation();
  const { trackEvent } = useTrackAnalyticsEvent();
  const query = queryString.stringify({ ...filters, locale: language });
  const { t } = useTranslation();

  return useMutation<Blob, AxiosError, unknown, unknown>({
    mutationFn: () => api.fetchInteractionActivitiesReport(query),
    onSuccess: (blob) => {
      saveBlob(blob, `${t('report')}.xlsx`);
      trackEvent(AnalyticsEvent.DownloadSessionOverview);
    },
  });
};

export const useInteractionActivity = (activityId: string) =>
  useQuery<InteractionActivityEntity, AxiosError>({
    queryKey: interactionActivitiesKeys.detail(activityId),
    queryFn: () => api.fetchInteractionActivity(activityId),
  });

export const useCreateInteractionActivity = ({
  recordId,
  userId,
  myCalendarId,
  calendarQuery,
}: {
  recordId?: string;
  userId?: string;
  myCalendarId?: string;
  calendarQuery?: { startDate: Date; endDate: Date };
}): UseMutationResult<
  InteractionActivityEntity,
  AxiosError,
  {
    trajectoryId: string;
    values: CreateInteractionActivity;
    type: ActivityType;
    recordId?: string;
  },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ trajectoryId, type, values }) => {
      const correctBody = {
        ...values,
        status:
          values.status === EventStatuses.cancelled || values.status === EventStatuses.no_show ? values.status : null,
      };

      return api.createInteractionActivity(trajectoryId, type, correctBody);
    },
    onSuccess: (_, variables) => {
      if (recordId) {
        queryClient.invalidateQueries({
          queryKey: trajectoryKeys.recordAll(recordId, queryString.stringify(activeTrajectoriesFetchParams)),
        });
      }
      queryClient.invalidateQueries({ queryKey: activitiesKeys.list(variables.trajectoryId, 'pastActivities=0') });
      queryClient.invalidateQueries({ queryKey: activitiesKeys.list(variables.trajectoryId, 'pastActivities=1') });
      queryClient.invalidateQueries({ queryKey: trajectoryKeys.detail(variables.trajectoryId) });
      if (calendarQuery && myCalendarId) {
        queryClient.invalidateQueries({
          queryKey: calendarKeys.externalEvents(queryString.stringify(calendarQuery), myCalendarId),
        });
      }
      if (userId && variables.recordId) {
        queryClient.invalidateQueries({
          queryKey: trajectoryKeys.recordAll(variables.recordId, queryString.stringify(activeTrajectoriesFetchParams)),
        });
      }
    },
  });
};

export const useEditInteractionActivity = ({
  trajectoryId,
  userId,
  myCalendarId,
  calendarQuery,
}: {
  trajectoryId?: string;
  filters?: InteractionActivitiesFilters;
  userId?: string;
  myCalendarId?: string;
  calendarQuery?: { startDate: Date; endDate: Date };
}): UseMutationResult<
  InteractionActivityEntity,
  AxiosError,
  { activityId: string; body: Partial<EditSessionFormValues> },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ activityId, body }) => {
      const correctBody = body.status
        ? {
            ...body,
            status:
              body.status === EventStatuses.cancelled || body.status === EventStatuses.no_show ? body.status : null,
          }
        : body;
      return api.patchInteractionActivity(activityId, correctBody);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: interactionActivitiesKeys.all() });

      if (trajectoryId) {
        queryClient.invalidateQueries({ queryKey: activitiesKeys.list(trajectoryId, 'pastActivities=0') });
        queryClient.invalidateQueries({ queryKey: activitiesKeys.list(trajectoryId, 'pastActivities=1') });
        queryClient.invalidateQueries({ queryKey: trajectoryKeys.detail(trajectoryId) });
      }
      if (userId && calendarQuery && myCalendarId) {
        queryClient.invalidateQueries({
          queryKey: calendarKeys.externalEvents(queryString.stringify(calendarQuery), myCalendarId),
        });
      }
    },
  });
};

export const useUploadFileToInteractionActivity = (
  trajectoryId?: string,
): UseMutationResult<
  InteractionActivityEntity,
  AxiosError,
  { activityId: string; body: { files: string[] } },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ activityId, body }) => api.patchInteractionActivity(activityId, body),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: interactionActivitiesKeys.detail(variables.activityId) });
      queryClient.invalidateQueries({ queryKey: activitiesKeys.list(trajectoryId, 'pastActivities=0') });
      queryClient.invalidateQueries({ queryKey: activitiesKeys.list(trajectoryId, 'pastActivities=1') });
      queryClient.invalidateQueries({ queryKey: trajectoryKeys.detail(trajectoryId) });
    },
  });
};

export const useDeleteFileFromInteractionActivity = (
  activityId: string,
  trajectoryId?: string,
  recordId?: string,
  sortDirection?: SortDirection,
): UseMutationResult<unknown, any, string, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (fileId) => api.deleteFileFromActivity(fileId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: interactionActivitiesKeys.detail(activityId) });
      queryClient.invalidateQueries({ queryKey: activitiesKeys.list(trajectoryId, 'pastActivities=0') });
      queryClient.invalidateQueries({ queryKey: activitiesKeys.list(trajectoryId, 'pastActivities=1') });
      queryClient.invalidateQueries({ queryKey: trajectoryKeys.detail(trajectoryId) });
      queryClient.invalidateQueries({ queryKey: notesKeys.record(recordId, NotesType.TRAJECTORY, sortDirection) });
    },
  });
};

export const useRemoveInteractionActivity = (trajectoryId: string, interactionId: string, userId?: string) => {
  const queryClient = useQueryClient();
  const { mutate: removeInteraction } = useRemoveInteraction(userId);

  return useMutation<void, AxiosError, string, unknown>({
    mutationFn: (activityId: string) => api.removeInteractionActivity(activityId),
    onSuccess: (_, sessionId) => {
      const futureActivitiesData = queryClient.getQueryData<{ pages: { items: Activity[] }[] }>(
        activitiesKeys.list(trajectoryId, 'pastActivities=0'),
      );
      const isFutureActivity = futureActivitiesData?.pages
        ?.reduce((acc, cur) => [...acc, ...cur.items], [])
        .filter((i) => i.id === sessionId).length;
      // eslint-disable-next-line
      isFutureActivity
        ? queryClient.invalidateQueries({ queryKey: activitiesKeys.list(trajectoryId, 'pastActivities=0') })
        : queryClient.invalidateQueries({ queryKey: activitiesKeys.list(trajectoryId, 'pastActivities=1') });
      queryClient.invalidateQueries({ queryKey: trajectoryKeys.detail(trajectoryId) });
      removeInteraction(interactionId);
    },
  });
};
