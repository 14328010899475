import React, { useContext } from 'react';
import { getNameInitials, getTeamInitials } from 'app/utils';
import cx from 'classnames';
import { Avatar as MUIAvatar } from '@mui/material';
import { TextSize, themeMUI } from 'ui/utils/themeMUI';
import { PUIText } from '../text-pui';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import s from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { TooltipContext } from 'app/utils/context';

export interface AvatarProps {
  id: string;
  src?: string;
  firstName?: string;
  lastName?: string;
  size?: number;
  className?: string;
  textSize?: TextSize;
  color?: string;
}

export const Avatar = ({
  id,
  firstName = 'Firstname',
  lastName = 'Lastname',
  className,
  size = 44,
  textSize = themeMUI.typography.l,
  color,
  ...props
}: AvatarProps) => {
  const { t } = useTranslation();

  const tooltipProps = useContext(TooltipContext);

  return (
    <div key={id} className={s.root} data-tooltip-id={`assignee-${id}`}>
      <ReactTooltip id={`assignee-${id}`} {...tooltipProps} delayShow={100} delayUpdate={100}>
        {lastName ? `${firstName} ${lastName}` : `${firstName} (${t('team')})`}
      </ReactTooltip>
      <MUIAvatar
        sx={{ bgcolor: color ?? themeMUI.palette.grey[200], width: size, height: size }}
        className={cx(s.root, className)}
        {...props}
      >
        <PUIText className={s.rootInitials} size={{ ...textSize, textSize: textSize.fontSize }}>
          {lastName ? getNameInitials(firstName, lastName) : getTeamInitials(firstName)}
        </PUIText>
      </MUIAvatar>
    </div>
  );
};

export const AvatarSmall = (props: AvatarProps) => <Avatar size={24} {...props} textSize={themeMUI.typography.xs} />;
