import { trajectoryKeys, recordKeys } from 'data/utils/hookKeys';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { Trajectory, ArrayResponseType, TrajectoryEntity, CreateTrajectory, EditTrajectory } from 'data/types';
import { AxiosError } from 'axios';

import queryString from 'qs';
import { SortDirection } from 'data/enums';

import * as api from '../actions-query';

interface TrajectoriesSearchParams {
  pastTrajectories?: number;
  limit?: number;
  offset?: number;
}

export const activeTrajectoriesFetchParams: TrajectoriesSearchParams = { pastTrajectories: 0, limit: 0, offset: 0 };
export const endedTrajectoriesFetchParams: TrajectoriesSearchParams = { pastTrajectories: 1, limit: 0, offset: 0 };

export const useTrajectories = (recordId: string) =>
  useQuery<Omit<ArrayResponseType<Trajectory>, 'total'>, AxiosError>({
    queryKey: trajectoryKeys.clientAll(recordId),
    queryFn: () => api.fetchRecordClientTrajectories(recordId),
  });

export const usePastTrajectories = (recordId: string) =>
  useQuery<Omit<ArrayResponseType<Trajectory>, 'total'>, AxiosError>({
    queryKey: trajectoryKeys.clientPast(recordId),
    queryFn: () => api.fetchRecordClientPastTrajectories(recordId, queryString.stringify({ pastTrajectories: 1 })),
  });

export const useAffiliateTrajectories = (recordId: string) =>
  useQuery<Omit<ArrayResponseType<Trajectory>, 'total'>, AxiosError>({
    queryKey: trajectoryKeys.clientAll(recordId),
    queryFn: () => api.fetchAffiliateRecordTrajectories(recordId),
  });

export const usePastAffiliateTrajectories = (recordId: string) =>
  useQuery<Omit<ArrayResponseType<Trajectory>, 'total'>, AxiosError>({
    queryKey: trajectoryKeys.clientPast(recordId),
    queryFn: () => api.fetchAffiliateRecordPastTrajectories(recordId, queryString.stringify({ pastTrajectories: 1 })),
  });

export const useRecordTrajectories = (recordId: string, searchParams?: TrajectoriesSearchParams) => {
  const query = queryString.stringify(searchParams);
  return useQuery<ArrayResponseType<Trajectory>, AxiosError>({
    queryKey: trajectoryKeys.recordAll(recordId, query),
    queryFn: () => api.fetchRecordTrajectories(recordId, query),
  });
};

export const useCreateTrajectory = (): UseMutationResult<
  TrajectoryEntity,
  AxiosError,
  { recordId: string; values: CreateTrajectory },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ recordId, values }) => api.createTrajectory(recordId, values),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: recordKeys.detail(variables.recordId) });
    },
  });
};

export const useEditTrajectory = (
  recordId: string,
): UseMutationResult<TrajectoryEntity, AxiosError, { trajectoryId: string; values: EditTrajectory }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ trajectoryId, values }) => api.editTrajectory(trajectoryId, values),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: trajectoryKeys.recordAll(recordId, queryString.stringify(endedTrajectoriesFetchParams)),
      });
      queryClient.invalidateQueries({
        queryKey: trajectoryKeys.recordAll(recordId, queryString.stringify(activeTrajectoriesFetchParams)),
        refetchType: 'all',
      });
      queryClient.invalidateQueries({ queryKey: trajectoryKeys.clientAll(recordId) });
      queryClient.invalidateQueries({ queryKey: trajectoryKeys.detail(variables.trajectoryId), refetchType: 'all' });
      queryClient.invalidateQueries({ queryKey: recordKeys.detail(recordId) });
    },
  });
};

export const useTrajectory = (id?: string) =>
  useQuery<Trajectory, AxiosError>({
    queryKey: trajectoryKeys.detail(id),
    queryFn: () => api.fetchTrajectory(id),
    enabled: !!id,
    refetchOnMount: false,
  });

export const useTrajectoryClient = (id: string) =>
  useQuery<Trajectory, AxiosError>({
    queryKey: trajectoryKeys.detail(id),
    queryFn: () => api.fetchTrajectoryClient(id),
  });

export const useAllRecordTrajectories = (
  recordId: string,
  params: { sort: { [key in string]?: SortDirection } },
): UseQueryResult<ArrayResponseType<Trajectory>, AxiosError> =>
  useQuery<ArrayResponseType<Trajectory>, AxiosError>({
    queryKey: trajectoryKeys.clientAll(recordId),
    queryFn: () => api.fetchAllRecordTrajectories(recordId, queryString.stringify(params)),
  });
