import { UseMutationResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import queryString from 'qs';

import { ArrayResponseType, CreateTagFields, Tag } from 'data/types';
import { tagsKeys } from 'data/utils/hookKeys';

import { SortDirection, TagsSortNames } from 'data/enums';
import * as api from '../actions-query';
import { AxiosError } from 'axios';

export interface ITagsSearchParams {
  limit: number;
  offset: number;
  sort?: {
    [key in TagsSortNames]?: SortDirection;
  };
  filters?: {
    search?: string | null;
    type?: string;
  };
}

export const tagsDefaultSearchParams: ITagsSearchParams = {
  offset: 0,
  limit: 12,
  sort: {
    createdAt: SortDirection.desc,
  },
};

export const useTags = (searchParams: ITagsSearchParams = tagsDefaultSearchParams) => {
  const query = queryString.stringify(searchParams);
  return useQuery<ArrayResponseType<Tag>, AxiosError>({
    queryKey: tagsKeys.list(query),
    queryFn: () => api.fetchTags(query),
  });
};

export const useCreateTag = (
  searchParams: ITagsSearchParams = tagsDefaultSearchParams,
): UseMutationResult<unknown, any, { fields: CreateTagFields }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ fields }) => api.createTag(fields),
    onSuccess: () => {
      const query = queryString.stringify(searchParams);
      queryClient.invalidateQueries({ queryKey: tagsKeys.list(query) });
    },
  });
};

export const useEditTag = (
  searchParams: ITagsSearchParams = tagsDefaultSearchParams,
): UseMutationResult<unknown, any, { tagId: string; fields: CreateTagFields }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ fields, tagId }) => api.editTag(tagId, fields),
    onSuccess: () => {
      const query = queryString.stringify(searchParams);
      queryClient.invalidateQueries({ queryKey: tagsKeys.list(query) });
    },
  });
};

export const useDeleteTag = (
  searchParams: ITagsSearchParams = tagsDefaultSearchParams,
): UseMutationResult<unknown, any, { tagId: string }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ tagId }) => api.deleteTag(tagId),
    onSuccess: () => {
      const query = queryString.stringify(searchParams);
      queryClient.invalidateQueries({ queryKey: tagsKeys.list(query) });
    },
  });
};
