import { useDownloadFile, useFileLink, usePersonalNoteFile } from 'data/hooks';

export const useImagePreview = (imageId: string) => {
  const { data: fileURL, isPending: isFileLinkLoading } = useFileLink(imageId);
  const { mutate: downloadFile } = useDownloadFile();
  const { data: document, isPending: isFileLoading } = usePersonalNoteFile(imageId);

  const downloadImage = () => {
    if (document) downloadFile({ fileId: document.id, fileName: document.originalName });
  };

  return { isFileLinkLoading, isFileLoading, fileURL, downloadImage, document };
};
